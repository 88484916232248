import React, { useContext } from "react";
import Donate from "../../components/Donate/Donate";
import Leaderboard from "../../components/Leaderboard/Leaderboard";
import OtherWaysToParticipate from "../../components/OtherWaysToParticipate";
import QuizOfTheDay from "../../components/QuizOfTheDay/QuizOfTheDay";
import "./HomePage.scss";

import PageWrapper from "../../components/PageWrapper/PageWrapper";
import useWindowSize from "../../utils/useWindowSize";

import { ParticipantContext } from "../../App";
import homeHeroLg from "../../assets/images/headers/home/Home_L.png";
import homeHeroMd from "../../assets/images/headers/home/Home_M.png";
import homeHeroSm from "../../assets/images/headers/home/Home_S.png";
import homeHeroXl from "../../assets/images/headers/home/Home_XL.png";
import WordleGame from "../../components/WordleGame/WordleGame";
import { shouldShowWinners } from "../../utils/eventUtil";
import { getBanner } from "../../utils/imageUtils";

const banners = [homeHeroXl, homeHeroLg, homeHeroMd, homeHeroSm];

const HomePage: React.FC = (): React.ReactElement => {
  const { width } = useWindowSize();
  const participant = useContext(ParticipantContext);

  return (
    <PageWrapper bannerImage={getBanner(width, banners)}>
      <div className="home-page-container">
        <QuizOfTheDay />
        <WordleGame />
        <Leaderboard
          isLocalLeaderBoard={undefined}
          isWinnersLeaderBoard={shouldShowWinners()}
          participantData={participant}
        />
        <Donate />
        {/* <div className="code-hunt-wrapper">
            <CodeHunt />
          </div> */}
        <OtherWaysToParticipate />
      </div>
    </PageWrapper>
  );
};
export default HomePage;
