import React from "react";
import "./LinkButton.scss";
import { EButtonStyle } from "../../utils/buttonUtils";

type Props = {
  text: string;
  style?: EButtonStyle;
  href: string;
  newTab: boolean;
};

const LinkButton: React.FC<Props> = ({
  text,
  style,
  href,
  newTab,
}): React.ReactElement => {
  let buttonType = style || EButtonStyle.PRIMARY;
  const buttonClasses = ["app-button", `app-button-${buttonType}`];
  let target = "_self";
  if (newTab) {
    target = "_blank";
  }

  return (
    <div className="app-button-container link-button-container">
      <a
        target={target}
        className={buttonClasses.join(" ")}
        href={href}
        rel="noreferrer"
      >
        <span>{text}</span>
      </a>
    </div>
  );
};

export default LinkButton;
