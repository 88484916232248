import classNames from "classnames";
import React, { useEffect } from "react";
import { ReactComponent as BackspaceIcon } from "../../../../assets/images/Backspace.svg";
import Button from "../../../Button/Button";
import { getStatuses } from "../../lib/words";
import { WordleDataUserAnswers } from "../../models/wordleData";
import styles from "./Keyboard.module.scss";

interface KeyboardProps {
  onEnter: any;
  onDelete: any;
  onKeyDown: any;
  guesses: WordleDataUserAnswers;
  checkingAnswer: boolean;
  isGameLost: boolean;
  isGameWon: boolean;
}

const Keyboard = ({
  onEnter,
  onDelete,
  onKeyDown,
  guesses,
  checkingAnswer,
  isGameLost,
  isGameWon,
}: KeyboardProps) => {
  const charStatuses = getStatuses(guesses);

  useEffect(() => {
    const listener = (e) => {
      const key = e.key.toUpperCase();
      if (key === "BACKSPACE") return onDelete();
      if (key === "ENTER") return onEnter();
      if (key.length === 1 && key >= "A" && key <= "Z") onKeyDown(key);
    };

    window.addEventListener("keydown", listener);
    return () => {
      window.removeEventListener("keydown", listener);
    };
  });

  const handleClick = (key) => {
    if (key === "ENTER") return onEnter();
    if (key === "DELETE") return onDelete();

    onKeyDown(key);
  };

  const handleBackspace = (key) => {
    onDelete();
  };

  return (
    <div className={styles.keyboard}>
      <div className={styles.row}>
        {["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"].map((char) => (
          <Key
            key={char}
            value={char}
            status={charStatuses[char]}
            onClick={handleClick}
          />
        ))}
      </div>
      <div className={styles.row}>
        {["A", "S", "D", "F", "G", "H", "J", "K", "L"].map((char) => (
          <Key
            key={char}
            value={char}
            status={charStatuses[char]}
            onClick={handleClick}
          />
        ))}
      </div>
      <div className={styles.row}>
        {["Z", "X", "C", "V", "B", "N", "M"].map((char) => (
          <Key
            key={char}
            value={char}
            status={charStatuses[char]}
            onClick={handleClick}
          />
        ))}
        <Key
          value={<BackspaceIcon />}
          onClick={handleBackspace}
          status="action"
        />
      </div>
      <div className={styles.row}>
        {!isGameLost && !isGameWon && (
          <Button
            onClick={() => onEnter()}
            type="submit"
            value="Submit"
            disabled={checkingAnswer}
          />
        )}
        {isGameLost && (
          <div className={styles.gameLost}>
            Sorry, you didn&apos;t guess the right word in 6 attempts or less.
            Try again tomorrow for a new word and a new chance to earn points!
          </div>
        )}
        {isGameWon && (
          <div className={styles.gameWon}>
            Congrats! You guessed the word of the day!
          </div>
        )}
      </div>
    </div>
  );
};

interface KeyProps {
  value: any;
  status: any;
  onClick: any;
}

const Key = ({ value, status, onClick }: KeyProps) => {
  const classes = classNames({
    [styles.key]: true,
    [styles.absent]: status === "absent",
    [styles.present]: status === "present",
    [styles.correct]: status === "correct",
    [styles.action]: status === "action",
  });

  return (
    <button className={classes} onClick={() => onClick(value)}>
      {value}
    </button>
  );
};

export default Keyboard;
