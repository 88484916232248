import React, { FC } from "react";

interface LinkifyProps {
  content: string;
  keys: string[];
  url: string;
}

const Linkify: FC<LinkifyProps> = ({ content, keys, url }): JSX.Element => {
  const key = keys.find((key) => content.includes(key));
  const index = content.indexOf(key);

  return index !== -1 ? (
    <p>
      {content.slice(0, index)}
      <a href={url || ""} target="_blank" rel="noopener noreferrer">
        {key}
      </a>
      {content.slice(index + key?.length)}
    </p>
  ) : (
    <p>{content}</p>
  );
};

export default Linkify;
