import React from "react";
import SecurianImg from "../../assets/images/securian.png";
import "./Securian.scss";

function Securian() {
  return (
    <div className="securian-container">
      <img src={SecurianImg} alt="" />
      <p>
        “Congratulations, you found the Securyeti! Use this code: D7T2EW in the
        Events Page to claim your points”
      </p>
    </div>
  );
}

export default Securian;
