import React from "react";
import chatIcon from "../../assets/images/chat.png";
import flagIcon from "../../assets/images/flag.png";
import mailIcon from "../../assets/images/mail.png";
import useWindowSize, {
  deviceWidthBreakpoints,
} from "../../utils/useWindowSize";
import "./Footer.scss";

const Footer = () => {
  const { width } = useWindowSize();
  const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;
  const bugReportingEmail = "zenith@slalom.com";
  const feedbackEmail = "hacktober@slalom.com";

  return (
    <div className="footer-container">
      <footer
        className={`${
          width < MOBILE_MAX
            ? "footer-mobile"
            : width < TABLET_MAX
            ? "footer-tablet"
            : ""
        }`}
      >
        <div className="footer-text-container">
          <img src={chatIcon} alt="chat-icon" className="chat-icon" />
          <p>
            Have questions? Visit the{" "}
            <a href="/faqs" rel="noopener help">
              &apos;FAQ&apos;
            </a>{" "}
            page to learn more.
          </p>
        </div>
        <div className="footer-text-container">
          <img src={mailIcon} alt="mail-icon" className="mail-icon" />
          <p>
            Have feedback? Please share with{" "}
            <a
              href={`mailto: ${feedbackEmail}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {feedbackEmail}
            </a>
          </p>
        </div>
        <div className="footer-text-container">
          <img src={flagIcon} alt="flag-icon" className="flag-icon" />
          <p>
            Caught a bug? Report it to{" "}
            <a
              href={`mailto: ${bugReportingEmail}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {bugReportingEmail}
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
