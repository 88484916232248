import React, { useState } from "react";
import { useMutation } from "react-query";
import { postEventToUserCalendar } from "../../ApiHelper";
import Rocket from "../../assets/images/rocket.svg";
import "../EventCard/EventCard.scss";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import "./Buttons.scss";
import KudosButton from "./KudosButton";

enum AddToCalendarState {
  success = "Event added to users calendar",
  alreadyExists = "User is already in attendance list",
}

enum SettledStates {
  init,
  success = "The event has been added to your calendar",
  alreadyExists = "You are already in the attendance list for this event",
  error = "An error has occured while trying to add you to the event",
}
interface EventActionButtonProps {
  url: string;
  isPastEvent: boolean;
  isOngoing: boolean;
  liked: boolean;
  eventId: number;
  activityLikeId: number;
}

export const EventActionButton = ({
  url,
  isPastEvent,
  isOngoing,
  liked = false,
  eventId,
  activityLikeId,
}: EventActionButtonProps) => {
  // Buttons that can show up on an event card include:
  // * "Add to Calendar"
  // * "Learn More"
  // * "GIVE KUDOS" (only for past events)

  const [settledState, setSettledState] = useState<SettledStates>(
    SettledStates.init
  );
  const [inProgress, setProgress] = useState<boolean>(false);

  const [saveEventToCalendar] = useMutation(postEventToUserCalendar, {
    onSettled: (data) => {
      setProgress(false);
      if (data === AddToCalendarState.success) {
        setSettledState(SettledStates.success);
      } else if (data === AddToCalendarState.alreadyExists) {
        setSettledState(SettledStates.alreadyExists);
      } else setSettledState(SettledStates.error);
    },
  });

  const linkButtonTitle = isOngoing ? "Join Now" : "Add to Calendar";
  const iconSrc = isOngoing ? Rocket : null;

  const handleAddToCalendar = () => {
    if (typeof eventId !== "undefined" && eventId) {
      setProgress(true);
      saveEventToCalendar(eventId);
    }
  };

  const renderButtonOrMessage = () => {
    if (settledState === SettledStates.init) {
      return (
        <button
          className="app-button-container link-button-container app-button app-button-primary"
          onClick={handleAddToCalendar}
        >
          <LoadingSpinner isLoading={inProgress} size="large">
            <span>{linkButtonTitle}</span>
          </LoadingSpinner>
        </button>
      );
    } else {
      return settledState;
    }
  };

  return (
    <div className="event-card-primary-btn-wrapper">
      {isPastEvent ? (
        <KudosButton
          isGiveKudos={liked}
          eventId={eventId}
          activityLikeId={activityLikeId}
        />
      ) : isOngoing ? (
        <div className="app-button-container link-button-container">
          <a
            target="_blank"
            className="app-button app-button-primary join-now-button"
            href={url}
            rel="noopener noreferrer"
          >
            {iconSrc && (
              <img
                className="app-button__icon"
                src={iconSrc}
                alt="button icon"
              />
            )}
            <span>{linkButtonTitle}</span>
          </a>
        </div>
      ) : (
        renderButtonOrMessage()
      )}
    </div>
  );
};
