import React, { useEffect, useRef, useState } from "react";
import "./MiniGame.scss";
import { MiniGameClassOptions } from "./MiniGameClassOptions";
import {
  calculateSnowballSpeed,
  isCollision,
  saveToLocalStorage,
  grabHighScore,
} from "../../utils/miniGameHelper";

let USER_SCORE = 0;

const MiniGame = (): JSX.Element => {
  const [miniGameClassName, setMiniGameClassName] =
    useState<MiniGameClassOptions>(MiniGameClassOptions.BaseClass);

  const [activeGame, setActiveGame] = useState<boolean>(false);

  const [snowballSpeed, setSnowballSpeed] = useState(1500);

  const yetiRef = useRef<HTMLDivElement>(null);
  const snowballRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!activeGame) {
      USER_SCORE = 0;
    }
    const handleJumpEvent = (e: KeyboardEvent): void => {
      if (
        e.code === "Space" &&
        miniGameClassName !== MiniGameClassOptions.JumpClass
      ) {
        e.preventDefault();
        setMiniGameClassName(MiniGameClassOptions.JumpClass);
        setTimeout(() => {
          if (!activeGame) {
            setActiveGame(true);
          }
          setMiniGameClassName(MiniGameClassOptions.BaseClass);
        }, 500);
      }
    };
    document.addEventListener("keydown", handleJumpEvent);
    return () => {
      document.removeEventListener("keydown", handleJumpEvent);
    };
  }, [miniGameClassName, setMiniGameClassName, activeGame]);

  setInterval(() => {
    if (activeGame) {
      const yetiRect = yetiRef.current.getBoundingClientRect();
      // Will need to be updated when multiple snowballs are added:
      const snowballRect = snowballRef.current.getBoundingClientRect();

      if (isCollision(yetiRect, snowballRect)) {
        alert(`Game Over!`);
        saveToLocalStorage(USER_SCORE);
        setActiveGame(false);
        setSnowballSpeed(1500);
      }
    }
  }, 100);

  const onAnimationEnd = () => {
    setSnowballSpeed(calculateSnowballSpeed(USER_SCORE));

    snowballRef.current.classList.remove("snowball-animation");
    void snowballRef.current.offsetWidth;
    snowballRef.current.classList.add("snowball-animation");

    USER_SCORE = USER_SCORE + 1;
  };

  useEffect(() => {
    const sr = snowballRef.current;
    sr.addEventListener("animationend", onAnimationEnd);
    return () => sr.removeEventListener("animationend", onAnimationEnd);
  }, []);

  return (
    <div className="mini-game-container">
      {!activeGame ? (
        <div className="start-game-message">Hit the spacebar to start game</div>
      ) : (
        <></>
      )}
      <div ref={yetiRef} className={miniGameClassName} />
      <div
        ref={snowballRef}
        className={`snowball ${activeGame ? "snowball-animation" : ""}`}
        style={{ animationDuration: `${snowballSpeed}ms` }}
      />
      <div className={`cloud ${activeGame ? "cloud-animation" : ""}`} />
      <div className={`cloud2 ${activeGame ? "cloud2-animation" : ""}`} />
      <div className={`cloud3 ${activeGame ? "cloud3-animation" : ""}`} />
      <div className="mini-game-score">
        <div>high score: {grabHighScore()}</div>
        <div>current score: {USER_SCORE}</div>
      </div>
    </div>
  );
};

export default MiniGame;
