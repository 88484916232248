export enum ELeaderboardOption {
  Global = "Global",
  Market = "Market",
  Local = "Local",
}

export enum EWinnersLeaderboardOption {
  Global = "Global",
  Market = "Market",
}
