import { IImage } from "../models/image.model";
import { deviceWidthBreakpoints } from "./useWindowSize";

const { SM, MD, LG } = deviceWidthBreakpoints;

export const getDataImage = (image: IImage) =>
  image?.data
    ? `data:${image.contentType};${image.encoding},${image.data}`
    : require("../assets/images/slalom-logo-theme-default.svg");

// banners array should be arranged largest to smallest
export const getBanner = (width: number, banners: string[]): string =>
  width > LG
    ? banners[0]
    : width > MD
    ? banners[1]
    : width > SM
    ? banners[2]
    : banners[3];
