export const Roles = {
  solutionOwner: "Solution Owner",
  srSolutionOwner: "Senior Solution Owner",
  programSolutionOwner: "Program Solution Owner",
  leadSolutionOwner: "Lead Solution Owner",
  solutionAnalyst: "Solution Analyst",
  securityLead: "Security Lead",
  frontEndEngineer: "Front End Engineer",
  smeArchitect: "Lead Architect",
  smeDatabase: "Database Lead",
  xr: "XR Engineer",
  sme: "SME",
  xd: "Experience Design",
  lvd: "Lead Visual Designer",
  lux: "Lead UX Designer",
  ux: "UX Designer",
  srux: "Senior UX Designer",
  ld: "Lead Designer",
  securyetiDesigner: "Securyeti Designer",
  fullStackEngineer: "Full Stack Engineer",
  softwareEngineer: "Software Engineer",
  srSoftwareEngineer: "Sr Software Engineer",
  leadSoftwareEngineer: "Lead Software Engineer",
  dataEngineer: "Data Engineer",
  leadDataEngineer: "Lead Data Engineer",
  leadGenAIEngineer: "Lead GenAI Engineer",
  qualityLead: "Lead Quality Engineer",
  qualityEngineer: "Quality Engineer",
  architect: "Architect",
  leadArchitect: "Lead Architect",
  dataAnalytics: "Data & Analytics",
  devOps: "DevOps",
  producer: "Producer",
  artist: "Artist",
  lead: "Leadership",
  leadership: {
    projectLead: "Project Lead",
    projectSupport: "Project Support",
    festivalCreator: "Festival Creator",
    projectAdvisor: "Project Advisor",
    marketingLead: "Marketing Lead",
    eventsPlatformLead: "Events Platform Lead",
    design: "Design Producer",
    global: "Global Program Lead",
    projectManager: "Project Manager",
    founder: "Founder",
    isg: "Information Security & Governance",
    programDirector: "Program Director",
    vp: "VP",
    dataPrivacy: "Data Privacy",
    legal: "Legal",
    communicationLead: "Communication Lead",
    contentLead: "Content Lead",
    tmLeader: "TM Leadership Sponsor",
    roadie: "Roadie (Designer) -Market Lead",
    programLead: "Program Lead",
    programSponsor: "Program Sponsor",
  },
  developer: "Developer",
  arDeveloper: "AR Developer",
  codeHuntDeveloper: "Code Hunt Developer",
  designer: "Designer",
  infoSec: "InfoSec",
  leadARDesigner: "Lead AR Designer",
  sponsor: "Sponsor",
  leadDataArchitect: "Lead Data Architect",
  leadARArchitect: "Lead AR Architect",
  leadSolutionArchitect: "Lead Solution Architect",
  srArchitect: "Senior Architect",
  smeA: "SME Architect",
  witLeadership: "WIT Leadership",
  communicationsLead: "Communications Lead",
  assistantPM: "Assistant Program Manager",
  pm: "Program Manager",
  srVisualDesigner: "Senior Visual Designer",
  srDesigner: "Senior Designer",
};
