import React from "react";
import hackerHeroLg from "../../assets/images/headers/hacker/Hacker_L.png";
import hackerHeroMd from "../../assets/images/headers/hacker/Hacker_M.png";
import hackerHeroSm from "../../assets/images/headers/hacker/Hacker_S.png";
import hackerHeroXl from "../../assets/images/headers/hacker/Hacker_XL.png";
import trophyGreen from "../../assets/images/trophy-green-2.svg";
import LinkButton from "../../components/Buttons/LinkButton";
import Card from "../../components/Card/Card";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import ScavengerHunt from "../../components/ScavengerHunt/ScavengerHunt";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import SpotTheFake from "../../components/SpotTheFake/SpotTheFake";
import { EButtonStyle } from "../../utils/buttonUtils";
import { hasEventEnded } from "../../utils/eventUtil";
import { getBanner } from "../../utils/imageUtils";
import {
  getMainContainerClass,
  getPageContainerClass,
  getResponsiveBackgroundClass,
  getResponsiveClassName,
} from "../../utils/responsiveClassHelpers";
import useWindowSize from "../../utils/useWindowSize";
import "./HackerPage.scss";

const getInnerContainerClass = (width) =>
  `gallery-qr-bar-${getResponsiveClassName(width)}`;

// const getDescriptionContainerClass = (width) =>
//   `description-container-${getResponsiveClassName(width)}`;

const getAboutWelcomeSectionResponsiveClass = (width) =>
  `welcome-section-${getResponsiveClassName(width)}`;

const getAboutContactSectionResponsiveClass = (width) =>
  `contact-section-container-${getResponsiveClassName(width)}`;

const HackerPage: React.FC = (): React.ReactElement => {
  const { width } = useWindowSize();

  const banners = [hackerHeroXl, hackerHeroLg, hackerHeroMd, hackerHeroSm];

  // const showCodeHunt = true;

  return (
    <PageWrapper bannerImage={getBanner(width, banners)}>
      <div className="home-page-container">
        <div
          className={`app-page section-container ${getResponsiveBackgroundClass(
            width
          )}`}
        >
          <div className={getPageContainerClass(width)}>
            <div className={getMainContainerClass(width)}>
              {!hasEventEnded() && (
                <>
                  <SectionHeader
                    sectionTitleString={"Spot The Fake"}
                    width={width}
                  ></SectionHeader>
                  <Card containerClass="spot-the-fake-description">
                    <div className="points-container">
                      <img
                        src={trophyGreen}
                        alt="trophy-icon"
                        className="points-icon"
                      />
                      200 points
                    </div>
                    <p>
                      Do you have what it takes to tell AI generated art from
                      Human art? Each business day, you&apos;ll be able to
                      showcase your GenAI literacy by completing the Spot the
                      Fake challenge! The art refreshes every 24 hours, so
                      don&apos;t miss out!
                    </p>
                  </Card>
                  <section
                    className={`welcome-section contact-section ${getAboutContactSectionResponsiveClass(
                      width
                    )} ${getAboutWelcomeSectionResponsiveClass(width)}`}
                  >
                    <div
                      className={`spot-the-fake-bar ${getInnerContainerClass(
                        width
                      )}`}
                    >
                      <SpotTheFake />
                    </div>
                  </section>

                  {/* Adding hackathons section */}
                  {/* <HackathonsSection /> */}
                  <SectionHeader
                    sectionTitleString={"Hacktober Juice Shop"}
                    width={width}
                  ></SectionHeader>
                  <Card>
                    <div className="challenge-header-owasp">
                      <div className="owasp-points">
                        {" "}
                        <div className="challenge-points-left-align">
                          <img src={trophyGreen} alt="trophy icon" />
                          Prizes
                        </div>
                        <div className="owasp-points-desc">
                          The winning team will receive 600 points, second place
                          will receive 500, and third place 400 points.
                        </div>
                      </div>
                      <div className="owasp-description">
                        <div>
                          <div className="bold">HACKTOBER JUICE SHOP: BETA</div>{" "}
                          Try out our newest insecure code application, the
                          Hacktober Juice Shop! From now until Oct 31st, you and
                          your team have a chance to rack up points in our
                          latest capture-the-flag style feature. Work together
                          with friends or go at it alone to hack the most
                          insecure web application! All participants earn 200
                          points for participating.
                        </div>
                        <br />
                        <div>
                          <div className="bold">HOW TO PLAY</div> Visit the site
                          by clicking the REGISTER HERE button below and
                          navigate to the registration page on the top right
                          corner of that screen. Enter our registration code,
                          hacktober24. Sign up using your Slalom email and join
                          or create a team. Once you have created a CTF team use
                          the TEST YOUR SKILLS button to create a Juice Shop
                          instance for your team to get started!
                        </div>
                      </div>
                    </div>
                    <div className="juice-shop-button-container">
                      <LinkButton
                        text="REGISTER HERE"
                        style={EButtonStyle.PRIMARY}
                        href="http://ec2-23-22-210-0.compute-1.amazonaws.com/"
                        newTab={true}
                      />
                      <LinkButton
                        text="TEST YOUR SKILLS"
                        style={EButtonStyle.PRIMARY}
                        href="http://a92fbd3b8a1154b5b82c18f22fd74762-907590454.us-east-1.elb.amazonaws.com"
                        newTab={true}
                      />
                    </div>
                  </Card>
                </>
              )}
              {/* <Securian /> */}
              <ScavengerHunt />

              {/* {showCodeHunt ? (
                  <CodeHunt />
                ) : (
                  <>
                    <SectionHeader
                      sectionTitleString={"Digital Code Hunt"}
                      width={width}
                    ></SectionHeader>

                    <section
                      className={`codehunt-section welcome-section-about-card ${getAboutContactSectionResponsiveClass(
                        width
                      )} ${getAboutWelcomeSectionResponsiveClass(width)}`}
                    >
                      <Card
                        containerClass={`contact-card contact-card-${getResponsiveClassName(
                          width
                        )}`}
                      >
                        <div className="hacker-page-text-body-container">
                          <p>
                            <span className="bold">ATTENTION!:</span> The
                            Digital Code Hunt is coming soon! Check back next
                            week to play and ask our Securyeti Clippy some
                            questions. 👀
                          </p>
                        </div>
                      </Card>
                    </section>
                  </>
                )} */}
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};
export default HackerPage;
