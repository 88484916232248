const eventConfig = {
  startDate: new Date(2024, 8, 30),
  endDate: new Date(2024, 10, 1),
  winnersAnnounced: new Date(2024, 10, 1),
};

export enum EventStatus {
  Prelaunch,
  InProgress,
  EndAnnouncement,
  Winners,
}

export const getEventStatus = (): EventStatus => {
  const currentDate = new Date();

  const { startDate, endDate, winnersAnnounced } = eventConfig;

  if (currentDate < startDate) {
    return EventStatus.Prelaunch;
  } else if (currentDate >= startDate && currentDate <= endDate) {
    return EventStatus.InProgress;
  } else if (currentDate > endDate && currentDate < winnersAnnounced) {
    return EventStatus.Winners;
  } else if (currentDate >= winnersAnnounced) {
    return EventStatus.Winners;
  }

  return null;
};

export const isEventInPrelaunch = (): boolean =>
  getEventStatus() === EventStatus.Prelaunch;

export const isEventInProgress = (): boolean =>
  getEventStatus() === EventStatus.InProgress;

export const hasEventEnded = (): boolean => {
  const eventStatus = getEventStatus();

  return (
    eventStatus === EventStatus.EndAnnouncement ||
    eventStatus === EventStatus.Winners
  );
};

export const shouldShowWinners = (): boolean =>
  getEventStatus() === EventStatus.Winners;

export const getPageState = (prelaunch: boolean): string =>
  prelaunch ? "prelaunch" : "fullsite";
