import React from "react";
import virusBug from "../../assets/images/hacktober-23-virus-bug.png";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import useWindowSize from "../../utils/useWindowSize";
import "./PageBanner.scss";

type Props = {
  bannerImage: string;
  showBug?: boolean;
};

const PageBanner: React.FC<Props> = ({
  bannerImage,
  showBug,
}): React.ReactElement => {
  const { width } = useWindowSize();

  return (
    <div className="page-banner">
      <div className="banner-overlay"></div>
      {showBug && (
        <div className={`virus-bug ${getResponsiveClassName(width)}`}>
          <img src={virusBug} alt="bug" />
          <a className="virus-bug-link" href="/minigame" rel="noreferrer">
            {" "}
          </a>
        </div>
      )}
      <img
        src={bannerImage}
        alt="Themed banner unique to each page"
        className={`page-banner-img page-banner-img-${getResponsiveClassName(
          width
        )}`}
      />
    </div>
  );
};

export default PageBanner;
