import React from "react";
import ScrollToTopIcon from "../../assets/images/Hack_2024_backtotop.png";
import "./ScrollToTopButton.scss";

const ScrollToTopButton = () => (
  <div className="scroll-to-top-button">
    <img
      className="scroll-to-top-icon"
      src={ScrollToTopIcon}
      alt="scroll to top icon"
      onClick={() => window.scrollTo(0, 0)}
    />
  </div>
);

export default ScrollToTopButton;
