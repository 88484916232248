import React, { FC, useState } from "react";
import { useQuery } from "react-query";
import { getChallenges } from "../../ApiHelper";
import { Challenge } from "../../models/challenge.model";
import useWindowSize, {
  deviceWidthBreakpoints,
} from "../../utils/useWindowSize";
import Card from "../Card/Card";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import SectionHeader from "../SectionHeader/SectionHeader";
import ChallengeBoard from "./components/ChallengeBoard";
import ChallengeHeader from "./components/ChallengeHeader";
import ChallengeView from "./components/ChallengeView";
import "./ScavengerHunt.scss";

const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;

const Unavailable = () => (
  <Card>
    <p>
      <strong>ATTENTION!:</strong> There&apos;s more points to be gained in the
      Scavenger Hunt, but this feature is only available on the desktop version
      of the site. Make sure you don&apos;t miss this opportunity and jump over
      to your laptop when you can.
    </p>
  </Card>
);

const ScavengerHunt: FC = () => {
  const { width } = useWindowSize();
  const viewport =
    width <= MOBILE_MAX ? "mobile" : width <= TABLET_MAX ? "tablet" : "desktop";

  const [challenge, setChallenge] = useState<Challenge | null>(null);

  const { data, isLoading } = useQuery<Challenge[]>(
    ["scavenger"],
    getChallenges
  );

  const error = typeof data === "string" ? { message: data } : null;
  const points = !error && data?.reduce((prev, curr) => prev + curr?.points, 0);

  const upsert = (challenge: Challenge) => {
    const i = data.findIndex((c) => c.id === challenge.id);
    data[i] = challenge;
  };

  return (
    <div className="section-container">
      <SectionHeader
        sectionTitleString={"Scavenger Hunt"}
        width={width}
      ></SectionHeader>
      {viewport !== "desktop" ? (
        <Unavailable />
      ) : (
        <>
          <ChallengeHeader points={points} />
          <LoadingSpinner
            isLoading={isLoading}
            size="huge"
            error={error}
            color="#1FF808"
          >
            {challenge ? (
              <ChallengeView
                challenge={challenge}
                setChallenge={setChallenge}
                upsert={upsert}
              />
            ) : (
              <ChallengeBoard challenges={data} setChallenge={setChallenge} />
            )}
          </LoadingSpinner>
        </>
      )}
    </div>
  );
};

export default ScavengerHunt;
