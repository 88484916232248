import React, { useContext } from "react";
import { ParticipantContext } from "../../App";
import Card from "../../components/Card/Card";
import Leaderboard from "../../components/Leaderboard/Leaderboard";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import PrizesList from "../../components/PrizesList/PrizesList";
import ScrollToTop from "../../components/ScrollToTop";
import { Event } from "../../utils/consts/event";
import {
  getMainContainerClass,
  getPageContainerClass,
  getResponsiveBackgroundClass,
  getResponsiveClassName,
} from "../../utils/responsiveClassHelpers";
import useWindowSize from "../../utils/useWindowSize";
import "./WinnersPage.scss";

import homeHeroLg from "../../assets/images/headers/home/Home_L.png";
import homeHeroMd from "../../assets/images/headers/home/Home_M.png";
import homeHeroSm from "../../assets/images/headers/home/Home_S.png";
import homeHeroXl from "../../assets/images/headers/home/Home_XL.png";
import { shouldShowWinners } from "../../utils/eventUtil";
import { getBanner } from "../../utils/imageUtils";

const event = Event;

const getAboutWelcomeSectionResponsiveClass = (width) =>
  `welcome-section-${getResponsiveClassName(width)}`;

const getAboutContactSectionResponsiveClass = (width) =>
  `contact-section-container-${getResponsiveClassName(width)}`;

const prizesList: { title: string; text: string }[] = [
  {
    title: "Top 10 Global",
    text: "The top 10 global winners will receive a Hacktober branded Tarana Backpack Cooler!",
  },
  {
    title: "Global Winner",
    text: "The top global winner will recieve an Exclusive branded Hacktober jacket!",
  },
  {
    title: "Local Winner",
    text: "The top local winner in each office will recieve a Branded YETI insulated mug!",
  },
];

const banners = [homeHeroXl, homeHeroLg, homeHeroMd, homeHeroSm];

const WinnersPage: React.FC = () => {
  const { width } = useWindowSize();
  const participant = useContext(ParticipantContext);

  return (
    <ScrollToTop>
      <PageWrapper bannerImage={getBanner(width, banners)}>
        <div
          className={`app-page winners-page ${getResponsiveBackgroundClass(
            width
          )}`}
        >
          <div className={getPageContainerClass(width)}>
            <div className={getMainContainerClass(width)}>
              <section
                className={`welcome-section ${getAboutContactSectionResponsiveClass(
                  width
                )} ${getAboutWelcomeSectionResponsiveClass(width)}`}
              >
                <div id="winner-main-content">
                  <Card
                    containerClass={`contact-card contact-card-wide-${getResponsiveClassName(
                      width
                    )}`}
                  >
                    <div className="contact-section-title large">
                      {event.winnersPage.section1Title}
                    </div>
                    <div className="winners-page-text-body-container">
                      {event.winnersPage.section1Description}
                      <div className="winners-page-text-body-note">
                        {event.winnersPage.section1DescriptionNote}
                      </div>
                      <a href={event.winnersPage.section1DescriptionNoteLink}>
                        Reedhima Mandlik
                      </a>
                    </div>
                  </Card>
                </div>
              </section>
              <Leaderboard
                isLocalLeaderBoard={false}
                isWinnersLeaderBoard={shouldShowWinners()}
                participantData={participant}
              />
              <PrizesList prizes={prizesList} />
            </div>
          </div>
        </div>
      </PageWrapper>
    </ScrollToTop>
  );
};

export default WinnersPage;
