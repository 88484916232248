import React, { FC } from "react";
import { LinkButtonProps } from "./Buttons.interface";
import "./Buttons.scss";

const LinkButton: FC<LinkButtonProps> = ({
  text,
  iconSrc,
  style,
  href,
  newTab,
}): React.ReactElement => {
  let buttonType = style || "primary";
  const buttonClasses = ["app-button", `app-button-${buttonType}`];
  let target = "_self";
  if (newTab) {
    target = "_blank";
  }

  return (
    <div className="app-button-container link-button-container">
      <a
        target={target}
        className={buttonClasses.join(" ")}
        href={href}
        rel="noreferrer"
      >
        {iconSrc && (
          <img className="app-button__icon" src={iconSrc} alt="button icon" />
        )}
        <span>{text}</span>
      </a>
    </div>
  );
};

export default LinkButton;
