import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { getWordle, postWordle } from "../../ApiHelper";
import trophyIcon from "../../assets/images/qotdPoints2024.svg"; //Hactober 2024
import useWindowSize from "../../utils/useWindowSize";
import Card from "../Card/Card";
import LoadingSpinner from "../LoadingSpinner";
import SectionHeader from "../SectionHeader/SectionHeader";
import Grid from "./components/Grid";
import Keyboard from "./components/Keyboard";
import { MAX_CHALLENGES, MAX_WORD_LENGTH } from "./constants/settings";
import { isWordValid } from "./lib/words";
import "./styles/_transitionStyles.scss";
import styles from "./WordleGame.module.scss";

function WordleGame() {
  const { width } = useWindowSize();

  const [currentGuess, setCurrentGuess] = useState("");
  const [guesses, setGuesses] = useState([]);
  const [isJiggling, setIsJiggling] = useState(false);
  const [isGameWon, setIsGameWon] = useState(false);
  const [isGameLost, setIsGameLost] = useState(false);
  const [checkingAnswer, setCheckingAnswer] = useState(false);

  const showAlert = (e) => alert(e);

  const { data: wordleData, isLoading: worldeIsLoading } = useQuery(
    ["wordle"],
    getWordle
  );

  useEffect(() => {
    if (typeof wordleData !== "undefined" && wordleData)
      setGuesses(wordleData.userAnswers);
  }, [wordleData]);

  const [saveWordleGuess] = useMutation(postWordle);

  useEffect(() => {
    if (isGameWon) {
      setIsGameLost(false);
    }

    // eslint-disable-next-line
  }, [isGameWon]);

  // Check game winning or losing
  useEffect(() => {
    if (isGameWon) return;
    guesses.forEach((guess) => {
      if (guess.isCorrect) {
        setIsGameWon(true);
        return;
      }
    });

    if (guesses.length === MAX_CHALLENGES && !isGameWon) {
      setIsGameLost(true);
    }

    // eslint-disable-next-line
  }, [guesses, isGameWon]);

  const handleKeyDown = (letter) =>
    currentGuess.length < MAX_WORD_LENGTH &&
    !isGameWon &&
    setCurrentGuess(currentGuess + letter);

  const handleDelete = () =>
    setCurrentGuess(currentGuess.slice(0, currentGuess.length - 1));

  const handleEnter = async () => {
    setCheckingAnswer(true);
    if (isGameWon || isGameLost) return;

    if (currentGuess.length < MAX_WORD_LENGTH) {
      setIsJiggling(true);
      setCheckingAnswer(false);
      return showAlert("Not enough letters", "error");
    }

    if (!isWordValid(currentGuess)) {
      setIsJiggling(true);
      setCheckingAnswer(false);
      return showAlert("Not a valid guess", "error");
    }
    const guessResponse = await saveWordleGuess({
      wordleId: wordleData.wordleId,
      answer: currentGuess,
    });

    await getWordle();

    setGuesses([...guesses, guessResponse]);

    setCurrentGuess("");
    setCheckingAnswer(false);
  };

  return (
    <div className="section-container">
      <div className="section-qotd">
        <SectionHeader sectionTitleString="Wordle" width={width} />
        <div className="quiz-of-the-day">
          <Card containerClass="quiz-description">
            <div className="points-container">
              <img
                src={trophyIcon}
                alt="database-icon"
                className="database-icon"
              />
              <span className="points-container__title">100 points</span>
            </div>
            <p>
              Test your cyber spooky knowledge with our brand new Wordle! You
              get six chances to guess a five-letter word related to Halloween
              or Cybersecurity every 24 hours. Get it right, and you&apos;ll win
              some points!
            </p>
          </Card>
        </div>

        <Card containerClass="quiz-content">
          <LoadingSpinner isLoading={worldeIsLoading} size="lg">
            <div className={styles.container}>
              <Grid
                currentGuess={currentGuess}
                guesses={guesses}
                isJiggling={isJiggling}
                setIsJiggling={setIsJiggling}
              />
              <Keyboard
                checkingAnswer={checkingAnswer}
                onEnter={handleEnter}
                onDelete={handleDelete}
                onKeyDown={handleKeyDown}
                guesses={guesses}
                isGameLost={isGameLost}
                isGameWon={isGameWon}
              />
            </div>
          </LoadingSpinner>
        </Card>
      </div>
    </div>
  );
}

export default WordleGame;
