import React from "react";

// BREAK POINTS
// 500 - 705: Mobile
// 705 - 1182: Tablet
// 1182 - 1920: Desktop
// 1920 & above: Extra Large Desktop

export const deviceWidthBreakpoints = {
  MOBILE_MAX: 705,
  TABLET_MAX: 1182,
  LAPTOP_MAX: 1920,
  SM: 480,
  MD: 960,
  LG: 1440,
  XL: 2560,
};

export default function useWindowSize() {
  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  function changeWindowSize() {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
  }

  React.useEffect(() => {
    window.addEventListener("resize", changeWindowSize);

    return () => {
      window.removeEventListener("resize", changeWindowSize);
    };
  }, []);

  return windowSize;
}
