import React from "react";
import LinkButton from "../../components/LinkButton/LinkButton";
import { EButtonStyle } from "../../utils/buttonUtils";
import useWindowSize from "../../utils/useWindowSize";
import Card from "../Card/Card";
import SectionHeader from "../SectionHeader/SectionHeader";
import "./Donate.scss";

const Donate: React.FC = () => {
  const { width } = useWindowSize();

  return (
    <div className="donate-section-container section-container">
      <SectionHeader
        sectionTitleString={"Hacker Page"}
        width={width}
      ></SectionHeader>
      <Card containerClass="donate-description">
        <div className="donate-container">
          <p>Check out the Hacker page</p>
        </div>
        <div className="donate-text-container">
          <p>
            There are more ways to earn points on the Hacker page! Test out your
            skills to more cyber-security challenges.
          </p>
        </div>
        <div className="app-button-container">
          <LinkButton
            text="GO&nbsp;HACK"
            style={EButtonStyle.PRIMARY}
            href="/hacker"
            newTab={true}
          />
        </div>
      </Card>
    </div>
  );
};

export default Donate;
