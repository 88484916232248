import { DropdownItemProps } from "semantic-ui-react";
import { FilterItem } from "../../models/filter.model";

export enum EEventsOption {
  Upcoming = "Upcoming",
  Past = "Past",
}

/**
 * Returns an array of unique categories from the given `events` array as an
 * array of `DropdownItemProps` objects. Categories with an empty or falsy
 * `event.categoryName` value are excluded.
 * @param {[]} events - An array of event objects.
 * @returns {DropdownItemProps[]} An array of unique categories as `DropdownItemProps` objects.
 */
export function getCategoryOptionsFromEvents(events): DropdownItemProps[] {
  const categoriesOptionsBase: DropdownItemProps[] = [
    {
      key: "all",
      text: "All",
      value: "all",
    },
  ];
  let categoriesOptionsFromEvents: DropdownItemProps[] = [];
  if (events) {
    const categories: string[] = Array.from(
      new Set(
        events
          .map((event) => event.categoryName)
          .filter(Boolean)
          .sort()
      )
    );
    categoriesOptionsFromEvents = categories.map((category) => ({
      key: category,
      text: category,
      value: category,
    }));
  }
  return [...categoriesOptionsBase, ...categoriesOptionsFromEvents];
}

export const getMarketFilterOptions = (
  eventData: { hostMarket?: string }[]
): FilterItem<any>[] => {
  if (!eventData?.length) {
    return [];
  }

  return Array.from(
    new Set(eventData.map((event) => event.hostMarket).filter(Boolean))
  ).map((market) => ({
    key: market,
    text: market,
    value: market,
  }));
};
