import React, { useState } from "react";
import { ENavLink } from "../TopNavigationBar";
import { deviceWidthBreakpoints } from "../../../utils/useWindowSize";
import { NavLink } from "react-router-dom";

type NavHamburgerMenuProps = {
  width: number;
  participantData: any;
  navLinks: ENavLink[];
};

const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;

const NavHamburgerMenu: React.FC<NavHamburgerMenuProps> = ({
  width,
  participantData,
  navLinks,
}): React.ReactElement => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  const handleToggle = () => {
    setNavbarOpen(!navbarOpen);
  };

  const closeMenu = () => {
    setNavbarOpen(false);
  };

  return (
    <div className="nav-hamburger nav-Container">
      <div
        id="nav-icon"
        onClick={handleToggle}
        className={navbarOpen ? "open" : ""}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>

      <ul
        className={`nav-menu ${
          navbarOpen
            ? `${
                width <= MOBILE_MAX && navbarOpen
                  ? "nav-show-mobile"
                  : "nav-show"
              }`
            : ""
        }`}
      >
        {width > TABLET_MAX && (
          <li className="mobile-nav-rankings">
            <div>
              <div>Local: #{participantData?.marketRank || "X"} </div>
            </div>
            <div>
              <div>Global: #{participantData?.globalRank || "X"} </div>
            </div>
            <div>
              <div>Points {participantData?.totalPoints || "0"} </div>
            </div>
          </li>
        )}
        {navLinks.map((link, index) => (
          <li key={index} onClick={() => closeMenu()}>
            <NavLink
              className={"nav-link-open"}
              key={index}
              exact={link === "Home"}
              to={
                link !== "Home"
                  ? `/${link.toLowerCase().replace(/ /g, "")}`
                  : "/"
              }
            >
              {link}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NavHamburgerMenu;
