import React from "react";
import { NavLink } from "react-router-dom";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import "./NavLogo.scss";

type NavLogoProps = {
  width: number;
  wrap?: boolean;
};

const NavLogo: React.FC<NavLogoProps> = ({ width, wrap }: NavLogoProps) => {
  return (
    <NavLink
      to="/"
      className={`nav-logo-${getResponsiveClassName(width)} ${
        wrap && "top-left"
      }`}
    >
      <img
        src={require("../../assets/images/slalom_logo.png")}
        alt="logo"
        width="78px"
      />
    </NavLink>
  );
};

export default NavLogo;
