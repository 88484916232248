export const isCollision = (r1: DOMRect, r2: DOMRect): boolean => {
  return (
    r1.left < r2.right &&
    r1.top < r2.bottom &&
    r1.right > r2.left &&
    r1.bottom > r2.top
  );
};

export const calculateSnowballSpeed = (USER_SCORE: number): number => {
  const rs = Math.random() * 500 + 1000;
  const us = Math.max(
    -1.2 * (USER_SCORE * USER_SCORE) - 20 * USER_SCORE + 500,
    -400
  );
  return rs + us;
};

const HIGH_SCORE_KEY = "mini-game-high-score";

export const saveToLocalStorage = (score: number): void => {
  const currentHighScore = localStorage.getItem(HIGH_SCORE_KEY);

  if (currentHighScore === null) {
    localStorage.setItem(HIGH_SCORE_KEY, score.toString());
  } else if (Number.parseInt(currentHighScore) < score) {
    localStorage.setItem(HIGH_SCORE_KEY, score.toString());
  }
};

export const grabHighScore = (): number => {
  const currentHighScore = localStorage.getItem(HIGH_SCORE_KEY);

  if (currentHighScore === null) {
    return 0;
  } else {
    return Number.parseInt(currentHighScore);
  }
};
