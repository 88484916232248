import React, { FC } from "react";
import { ELeaderboardOption } from "../Leaderboard.util";
import LoadingSpinner from "../../LoadingSpinner";
import LeaderListing from "../LeaderListing/LeaderListing";
import { deviceWidthBreakpoints } from "../../../utils/useWindowSize";
import { otherLeaderParticipants } from "../";

const { TABLET_MAX } = deviceWidthBreakpoints;

type LoadLeaderListingProps = {
  width: any;
  sortedParticipants: any;
  selectedOption: ELeaderboardOption;
  error: any;
  isLoading: boolean;
};

const leaderListingContainerClassName = (width) =>
  `leader-listing-container${width > TABLET_MAX ? "" : "-mobile"}`;

const LoadLeaderListing: FC<LoadLeaderListingProps> = ({
  width,
  sortedParticipants,
  selectedOption,
  error,
  isLoading,
}: LoadLeaderListingProps) => {
  return (
    <div className={leaderListingContainerClassName(width)}>
      {sortedParticipants &&
        otherLeaderParticipants(sortedParticipants).map((participant, idx) => (
          <LoadingSpinner
            key={idx}
            isLoading={isLoading}
            error={error}
            color={"#00A4F9"}
            size={"small"}
          >
            <LeaderListing
              key={participant.user}
              {...participant}
              selectedOption={selectedOption}
              rank={idx + 4}
              lastRow={sortedParticipants.length === idx + 1}
            />
          </LoadingSpinner>
        ))}
    </div>
  );
};

export default LoadLeaderListing;
