import React, { useEffect } from "react";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import "./ErrorPage.scss";

import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import LinkButton from "../../components/LinkButton/LinkButton";
import { EButtonStyle } from "../../utils/buttonUtils";
import useWindowSize, {
  deviceWidthBreakpoints,
} from "../../utils/useWindowSize";

import errorImageUltrawide from "../../assets/images/Error_XL.png";

import errorImageDesktop from "../../assets/images/Error_L.png";

import errorImageTablet from "../../assets/images/Error_M.png";

import errorImageMobile from "../../assets/images/Error_S.png";

interface ErrorPageProps {
  setShowNavBars: (setVal: boolean) => void;
}

const { LAPTOP_MAX, TABLET_MAX, MOBILE_MAX } = deviceWidthBreakpoints;

const getBtnResponsiveClass = (width: number): string => {
  return `error-btn-container-${getResponsiveClassName(width)}`;
};

const getErrorPageContainerClass = (width: number): string => {
  return `error-page-container-${getResponsiveClassName(width)}`;
};

const renderUltrawideBackgroundImg = (): JSX.Element => (
  <div>
    <img
      src={errorImageUltrawide}
      alt="404 background"
      className="errorpage-background-image"
    ></img>
  </div>
);
const renderDesktopBackgroundImg = (): JSX.Element => (
  <div>
    <img
      src={errorImageDesktop}
      alt="404 background"
      className="errorpage-background-image"
    ></img>
  </div>
);
const renderTabletBackgroundImg = (): JSX.Element => (
  <div>
    <img
      src={errorImageTablet}
      alt="404 background"
      className="errorpage-background-image"
    ></img>
  </div>
);
const renderMobileBackgroundImg = (): JSX.Element => (
  <div>
    <img
      src={errorImageMobile}
      alt="404 background"
      className="errorpage-background-image"
    ></img>
  </div>
);

const renderResponsiveBackgroundMedia = (width: number) =>
  width > LAPTOP_MAX
    ? renderUltrawideBackgroundImg()
    : width > TABLET_MAX
    ? renderDesktopBackgroundImg()
    : width > MOBILE_MAX
    ? renderTabletBackgroundImg()
    : renderMobileBackgroundImg();

const ErrorPage = ({ setShowNavBars }: ErrorPageProps) => {
  const { width } = useWindowSize();

  useEffect(() => {
    setShowNavBars(false);
  }, [setShowNavBars]);

  return (
    <>
      <div className={`app-page error-page`}>
        {renderResponsiveBackgroundMedia(width)}
        <div className={getErrorPageContainerClass(width)}>
          <div className={getBtnResponsiveClass(width)}>
            <LinkButton
              text="Contact Us"
              style={EButtonStyle.SECONDARY}
              href="mailto: zenith@slalom.com"
              newTab={true}
            />
            <Link to="/">
              <Button
                type="button"
                onClick={() => setShowNavBars(true)}
                value="Return Home"
              />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
