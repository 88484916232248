import React from "react";
import { Link } from "react-router-dom";
import prelaunchImageMobile from "../../assets/images/prelaunch-mobile.jpg";
import prelaunchImageTablet from "../../assets/images/prelaunch-tablet.jpg";
import Button from "../../components/Button/Button";
import LinkButton from "../../components/LinkButton/LinkButton";
import Video from "../../components/VideoBackground/Video";
import { EButtonStyle } from "../../utils/buttonUtils";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import useWindowSize, {
  deviceWidthBreakpoints,
} from "../../utils/useWindowSize";
import "./PrelaunchPage.scss";

const backgroundVid = require("../../assets/videos/Hacktober-2023.mp4");
const playlist_url =
  "https://open.spotify.com/playlist/3yjInozTQ87I9Et9h7k84A?si=8b6f81ec00dc44ed";

const { TABLET_MAX, MOBILE_MAX } = deviceWidthBreakpoints;

const renderDesktopBackgroundVid = (): JSX.Element => (
  <Video video={backgroundVid} showPlayPause={true} />
);
const renderTabletBackgroundImg = (): JSX.Element => (
  <div>
    <img
      src={prelaunchImageTablet}
      alt="page background"
      className="prelaunch-background-image"
    ></img>
  </div>
);
const renderMobileBackgroundImg = (): JSX.Element => (
  <div>
    <img
      src={prelaunchImageMobile}
      alt="page background"
      className="prelaunch-background-image"
    ></img>
  </div>
);

const renderResponsiveBackgroundMedia = (width: number) =>
  width > TABLET_MAX
    ? renderDesktopBackgroundVid()
    : width > MOBILE_MAX
    ? renderTabletBackgroundImg()
    : renderMobileBackgroundImg();

const PrelaunchPage = () => {
  const { width } = useWindowSize();

  return (
    <>
      <div
        className={`prelaunch-container prelaunch-container-${getResponsiveClassName(
          width
        )}`}
      >
        {renderResponsiveBackgroundMedia(width)}
        <section
          className={`prelaunch-topic-container-${getResponsiveClassName(
            width
          )}`}
        >
          <div
            className={`prelaunch-announcement-card-${getResponsiveClassName(
              width
            )}`}
          >
            <img
              src={require("../../assets/images/hacktober-2023-pumpkin.png")}
              className="prelaunch-announcement-img"
              alt="hacktober pumpkin"
            />
            <div className="prelaunch-announcement-text">
              Hacktober is almost upon us!
              <br></br>
              <br></br>
              While Hacktober launches Oct 1st, you don&apos;t have to wait to
              join in on the spooky fun. RSVP to our events below, or check out
              our Spotify playlist!
            </div>
            {/* {width > TABLET_MAX && (
              <img
                src={require("../../assets/images/hacktober-2023-pumpkin.png")}
                className="prelaunch-announcement-img"
                alt="hacktober pumpkin"
              />
            )} */}
          </div>
          <div
            className={`button-container-${getResponsiveClassName(
              width
            )} button-mt`}
          >
            <LinkButton
              style={EButtonStyle.SECONDARY}
              text="OPEN SPOTIFY"
              href={playlist_url}
              newTab={true}
            />

            <Link to="/events">
              <Button
                style={EButtonStyle.SECONDARY}
                type="button"
                onClick={null}
                value="EVENTS"
              />
            </Link>
            <Link to="/faqs">
              <Button
                style={EButtonStyle.SECONDARY}
                type="button"
                onClick={null}
                value="FAQ"
              />
            </Link>
          </div>
        </section>
      </div>
    </>
  );
};

export default PrelaunchPage;
