import moment from "moment-timezone";
import React from "react";
import EventCard from "./EventCard.jsx";

// Time logic strings
const zone_name = moment.tz.guess();
const startTime = (startDateTime) =>
  moment.tz(startDateTime, zone_name).format("LLLL");
const endTime = (endDateTime) =>
  moment.tz(endDateTime, zone_name).format("LLLL");

export const startTimeStr = (startDateTime) =>
  startTime(startDateTime)
    .slice(startTime(startDateTime).indexOf(":") - 2)
    .trim();
export const endTimeStr = (endDateTime) =>
  endTime(endDateTime).slice(endTime(endDateTime).indexOf(":") - 2);

export const dateStr = (startDateTime) =>
  startTime(startDateTime)
    .slice(
      startTime(startDateTime).indexOf(" "),
      startTime(startDateTime).indexOf(":") - 2
    )
    .trim();

export const weekdayStr = (startDateTime) =>
  startTime(startDateTime).slice(0, startTime(startDateTime).indexOf(","));
export const timezoneStr = moment.tz(zone_name).zoneAbbr();

// Event content logic
export const shortenContent = (content, maxLength) => {
  if (content.length > maxLength) {
    const trimmedString = content.substr(0, maxLength);
    return (
      trimmedString.substr(
        0,
        Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
      ) + "..."
    );
  } else {
    return content;
  }
};

const URL_REGEX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
const MAILTO_REGEX = /hacktober@slalom.com/;

const parseContentLinks = (content) => {
  return content.split(" ").map((part, index) => {
    return URL_REGEX.test(part) ? (
      <a
        href={part}
        target="_blank"
        rel="noopener noreferrer"
        key={`event-card-content-link-${index}`}
      >
        {part}&nbsp;
      </a>
    ) : MAILTO_REGEX.test(part) ? (
      <a href={`mailto:${part}`} target="_blank" rel="noopener noreferrer">
        {part}&nbsp;
      </a>
    ) : (
      part + " "
    );
  });
};

export const extendContent = (content) => {
  let keyIdx = 0;

  return content.split("\n").map((c) => (
    <div className="event-content" key={`event-content-${keyIdx++}`}>
      {parseContentLinks(c)}
    </div>
  ));
};

export const cleanUpName = (name) => {
  const regex = /^\[([^\]]+)\]\s*/;
  const match = regex.exec(name);
  return match ? name.replace(regex, "").trim() : name;
};

export const getMonth = (startDateTime) => {
  return dateStr(startDateTime).split(" ")[0].substring(0, 3);
};

export const getDay = (startDateTime) => {
  let date = dateStr(startDateTime).split(" ")[1].split(",")[0];

  return parseInt(date) < 10 ? `0${date}` : date;
};

export const getTime = (startDateTime) => {
  return startTimeStr(startDateTime).split(" ").join("").toLocaleLowerCase();
};

export default EventCard;
