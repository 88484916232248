import PropTypes from "prop-types";
import React, { FC } from "react";
import TrophyIcon from "../../../assets/images/trophy-green-2.svg";
import Card from "../../Card/Card";
import { ChallengeHeaderProps } from "../ScavengerHunt.interface";

const ChallengeHeader: FC<ChallengeHeaderProps> = ({ points }) => (
  <Card containerClass="challenge-header">
    <div className="challenge-points">
      <img src={TrophyIcon} alt="trophy icon" />
      Up to {points || "x"} Points
    </div>
    <p>
      Test your security chops by tackling a challenge from the Scavenger Hunt!
      Each day, users will be permitted to take one challenge from the
      grid/board. Beat all the challenges to accumulate the max amount of
      points. Remember, the grid/board security system only permits one
      submission per day. So fear not, venture into the digital world each day
      for an opportunity to champion a challenge and destroy the grid.
    </p>
  </Card>
);

ChallengeHeader.defaultProps = {
  points: undefined,
};

ChallengeHeader.propTypes = {
  points: PropTypes.number,
};

export default ChallengeHeader;
