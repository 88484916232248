import PropTypes from "prop-types";
import React, { FC, useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  dayFilterOptions,
  levelFilterOptions,
  timeFilterOptions,
  topicFilterOptions,
} from ".";
import { getEvents } from "../../ApiHelper";
import eventsHeroL from "../../assets/images/headers/events/Events_L.png";
import eventsHeroMd from "../../assets/images/headers/events/Events_M.png";
import eventsHeroSm from "../../assets/images/headers/events/Events_S.png";
import eventsHeroXL from "../../assets/images/headers/events/Events_XL.png";
import Card from "../../components/Card/Card";
import ClaimPoints from "../../components/ClaimPoints";
import DropdownFilter from "../../components/Dropdown";
import LinkButton from "../../components/LinkButton/LinkButton";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import { Event } from "../../utils/consts/event";
import { getBanner } from "../../utils/imageUtils";
import {
  getPageContainerClass,
  getResponsiveBackgroundClass,
  getResponsiveClassName,
} from "../../utils/responsiveClassHelpers";
import useWindowSize from "../../utils/useWindowSize";
import EventCardGrid from "./EventCardGrid";
import "./EventsPage.scss";
import { EEventsOption } from "./EventsPage.util";
import PastEventCardGrid from "./PastEventCardGrid";

type EventsPageProps = {
  showClaimPoints?: boolean;
  isPrelaunch?: boolean;
};

export const VIEW_RECORDINGS_LINK = Event.wrapPage.viewRecordingLink;
const showWarning = false;

export const getEventsPageState = (prelaunch) =>
  prelaunch ? "prelaunch" : "full-site";

type EventOptionsProps = {
  selectedOption: string;
  setSelectedOption: (selectedOption: EEventsOption) => void;
};

const EventOptions: FC<EventOptionsProps> = ({
  selectedOption,
  setSelectedOption,
}: EventOptionsProps): JSX.Element => {
  const isSelectedStyleApplied = (option: string) =>
    option === selectedOption
      ? "selected-event-option"
      : "unselected-event-option";
  const eventsOptions = [
    { title: "Upcoming", type: EEventsOption.Upcoming },
    { title: "Past", type: EEventsOption.Past },
  ];
  const { width } = useWindowSize();
  return (
    <div className={`events-options ${getResponsiveClassName(width)}`}>
      {eventsOptions.map((eventsOption) => {
        const { title, type } = eventsOption;
        return (
          <div
            key={title}
            className={`events-option ${isSelectedStyleApplied(
              title
            )} ${getResponsiveClassName(width)}`}
            onClick={() => setSelectedOption(type)}
          >
            {title}
          </div>
        );
      })}
    </div>
  );
};

const EventsPage: React.FC<EventsPageProps> = ({
  showClaimPoints,
  isPrelaunch,
}): React.ReactElement => {
  const { width } = useWindowSize();
  const [selectedOption, setSelectedOption] = useState<EEventsOption>(
    EEventsOption.Upcoming
  );
  // Commenting out office location selection for Hacktober 2023
  //const [selectedMarkets, setSelectedMarkets] = useState<string[]>([]);
  const [selectedMarkets] = useState<string[]>([]);
  const [selectedDays, setSelectedDays] = useState<string[]>(["0"]);
  const [selectedTimes, setSelectedTimes] = useState<string[]>(["all"]);
  const [speakerProfiles, setSpeakerProfiles] = useState<string[]>([]);
  const [selectedTopic, setSelectedTopic] = useState<string[]>([]);
  const [selectedLevel, setSelectedLevel] = useState<string[]>([]);

  const banners = [eventsHeroXL, eventsHeroL, eventsHeroMd, eventsHeroSm];

  const {
    data: eventsData,
    error: eventsError,
    isLoading: eventsAreLoading,
  } = useQuery<any[]>(["events"], getEvents);
  const filterContainerClass = `filter-container-${getResponsiveClassName(
    width
  )}`;
  // Commenting out office location selection for Hacktober 2023
  //const marketFilterOptions: FilterItem[] = getMarketFilterOptions(eventsData);
  useEffect(() => {
    console.log(eventsData);
  }, [eventsData]);

  return (
    <PageWrapper
      bannerImage={getBanner(width, banners)}
      isPrelaunch={isPrelaunch}
    >
      <div
        className={`app-page events-page__pink ${getResponsiveBackgroundClass(
          width
        )}`}
      >
        <div className={getPageContainerClass(width)}>
          {showWarning && (
            <section className="temp-card-container">
              <Card>
                <div className="prelaunch-card-title">
                  <span role="img" aria-label="horn">
                    📣
                  </span>{" "}
                  Hang Tight!!!
                </div>
                <div className="temp-card-body">
                  <span className="bold">
                    Add to calendar functionality will be up shortly.
                  </span>{" "}
                  We apologize for the inconvenience. Check back soon!
                </div>
              </Card>
            </section>
          )}
          <div className="events-container">
            {!isPrelaunch && <ClaimPoints />}
            <section className="section-container">
              <SectionHeader
                // className="events-tooltip"
                sectionTitleString={"Events"}
                width={width}
                tooltipContent={
                  <p>
                    To attend an event, click the{" "}
                    <b>&quot;Add to Calendar&quot;</b> button and you&apos;ll
                    receive an invite to that event.
                    <br></br>
                    <br></br>
                    See a <b>&quot;Join Now&quot;</b> button instead? That means
                    the event is happening right now! Click that button to be
                    taken directly to that call.
                    <br></br>
                    <br></br>
                    Loved the session you attended? Be sure to give the speaker
                    kudos on the events page under the <b>PAST</b> tab.
                  </p>
                }
              ></SectionHeader>
              <div
                className={
                  "app-card-display filter-container " + filterContainerClass
                }
              >
                <EventOptions
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                />
                <div className={`filter-row-${getResponsiveClassName(width)}`}>
                  {/* Commenting out office location selection for Hacktober 2023 */}
                  {/* <div>
                    <p className="dropdown-label">Office Location</p>
                    <DropdownFilter
                      placeholder="Select office location"
                      onChange={(event, data) =>
                        setSelectedMarkets(data.value as string[])
                      }
                      options={marketFilterOptions}
                      multiple
                      selection
                      clearable
                      className={`events-filter ${getResponsiveClassName(
                        width
                      )}`}
                      label={""}
                    />
                </div> */}
                  {selectedOption === EEventsOption.Past && (
                    <div>
                      <p className="dropdown-btn-label">View previous events</p>
                      <div
                        className={`dropdown-container ${getResponsiveClassName(
                          width
                        )}`}
                      >
                        <LinkButton
                          text={"VIEW RECORDINGS"}
                          href={VIEW_RECORDINGS_LINK}
                          newTab={true}
                        />
                      </div>
                    </div>
                  )}
                  <div>
                    <p className="dropdown-label">Filter by day</p>
                    <DropdownFilter
                      placeholder="Select day"
                      onChange={(event, data) =>
                        setSelectedDays(data.value as string[])
                      }
                      color="#deff4d"
                      options={dayFilterOptions}
                      multiple
                      selection
                      clearable
                      className={`events-filter ${getResponsiveClassName(
                        width
                      )}`}
                      label={""}
                    />
                  </div>

                  <div>
                    <p className="dropdown-label">Filter by time</p>
                    <DropdownFilter
                      placeholder="Select time"
                      onChange={(event, data) => {
                        setSelectedTimes(data.value as string[]);
                      }}
                      options={timeFilterOptions}
                      multiple
                      selection
                      clearable
                      className={`events-filter ${getResponsiveClassName(
                        width
                      )}`}
                      label={""}
                    />
                  </div>

                  <div>
                    <p className="dropdown-label">Filter by topic</p>
                    <DropdownFilter
                      placeholder="Select topic"
                      onChange={(event, data) => {
                        setSelectedTopic(data.value as string[]);
                      }}
                      options={topicFilterOptions}
                      multiple
                      selection
                      clearable
                      className={`events-filter ${getResponsiveClassName(
                        width
                      )}`}
                      label={""}
                    />
                  </div>

                  <div>
                    <p className="dropdown-label">Filter by experience level</p>
                    <DropdownFilter
                      placeholder="Select level"
                      onChange={(event, data) => {
                        setSelectedLevel(data.value as string[]);
                      }}
                      options={levelFilterOptions}
                      multiple
                      selection
                      clearable
                      className={`events-filter ${getResponsiveClassName(
                        width
                      )}`}
                      label={""}
                    />
                  </div>
                </div>
              </div>
              {selectedOption === EEventsOption.Upcoming ? (
                <div className="events-container">
                  <EventCardGrid
                    eventsAreLoading={eventsAreLoading}
                    eventsError={eventsError as string}
                    eventsData={eventsData}
                    selectedMarkets={selectedMarkets}
                    selectedDays={selectedDays}
                    selectedTimes={selectedTimes}
                    selectedTopic={selectedTopic}
                    selectedLevel={selectedLevel}
                    speakerProfiles={speakerProfiles}
                    setSpeakerProfiles={setSpeakerProfiles}
                  />
                </div>
              ) : (
                <div className="events-container">
                  <PastEventCardGrid
                    eventsAreLoading={eventsAreLoading}
                    eventsError={eventsError as string}
                    eventsData={eventsData}
                    selectedDays={selectedDays}
                    selectedMarkets={selectedMarkets}
                    selectedTopic={selectedTopic}
                    selectedLevel={selectedLevel}
                  />
                </div>
              )}
            </section>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

EventsPage.propTypes = {
  showClaimPoints: PropTypes.bool,
  isPrelaunch: PropTypes.bool,
};

export default EventsPage;
