import React from "react";
import { EButtonStyle } from "../../utils/buttonUtils";
import "./Button.scss";

type Props = {
  style?: string;
  type: "submit" | "button" | "reset";
  value?: string;
  disabled?: boolean;
  iconSrc?: string;
  classes?: string[];
  outerClasses?: string[];
  onClick: (event?: any) => void;
};

const Button: React.FC<Props> = ({
  style,
  type,
  value,
  disabled,
  iconSrc,
  classes,
  outerClasses,
  onClick,
}): React.ReactElement => {
  let buttonStyle = style || EButtonStyle.PRIMARY;
  const buttonClasses: string[] = (classes || []).concat([
    "app-button",
    `app-button-${buttonStyle}`,
  ]);

  const outerButtonClasses: string = (
    outerClasses || ["app-button-generic-container"]
  )
    .filter(Boolean)
    .join(" ");

  return (
    <div className={outerButtonClasses}>
      <button
        className={buttonClasses.filter(Boolean).join(" ")}
        type={type}
        onClick={onClick}
        disabled={disabled}
      >
        {iconSrc && (
          <img className="app-button__icon" src={iconSrc} alt="button icon" />
        )}
        <span>{value}</span>
      </button>
    </div>
  );
};

export default Button;
