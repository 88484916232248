import PropTypes from "prop-types";
import React, { FC } from "react";
import { ChallengeRowProps } from "../ScavengerHunt.interface";
import ChallengeTile from "./ChallengeTile";

const ChallengeRow: FC<ChallengeRowProps> = ({
  available,
  challenges,
  setChallenge,
  offset,
}) => (
  <div className={`challenge-row ${offset ? "offset" : null}`}>
    {challenges?.map((c) => (
      <ChallengeTile
        key={c.id}
        challenge={c}
        available={available}
        setChallenge={setChallenge}
      />
    ))}
  </div>
);

ChallengeRow.defaultProps = {
  offset: false,
};

ChallengeRow.propTypes = {
  offset: PropTypes.bool,
};

export default ChallengeRow;
