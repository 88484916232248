import React from "react";
import { Event } from "../../utils/consts/event";
import { isEventInPrelaunch, isEventInProgress } from "../../utils/eventUtil";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import useWindowSize from "../../utils/useWindowSize";
import Announcements from "../Announcements/Announcements";
import PageBanner from "../PageBanner/PageBanner";
import ScrollToTopButton from "../ScrollToTopButton/ScrollToTopButton";
import "./PageWrapper.scss";
// Adjust the margins for the page wrapper depending on if the header/footer are visible
const getDynamicWrapperClass = (baseClass: string, noHeader: boolean): string =>
  noHeader ? `${baseClass}-prelaunch` : `${baseClass}-fullsite`;

const event = Event;

interface PageWrapperProps {
  children: any;
  bannerImage: string;
  isPrelaunch?: boolean;
  showBug?: boolean;
}

const PageWrapper = ({
  bannerImage,
  children,
  isPrelaunch,
}: PageWrapperProps) => {
  const { width } = useWindowSize();
  const isBeforeEventStart = isEventInPrelaunch();
  const isInProgress = isEventInProgress();

  return (
    <div className={`${getDynamicWrapperClass("page-wrapper", isPrelaunch)}`}>
      {!!event?.announcements?.length && isInProgress && (
        <Announcements announcementData={event.announcements} />
      )}
      <PageBanner bannerImage={bannerImage} />
      <div
        className={`page-wrapper-content page-wrapper-content-${getResponsiveClassName(
          width
        )}`}
      >
        <div
          className={`${getDynamicWrapperClass(
            "page-bg-container",
            isBeforeEventStart
          )} ${getResponsiveClassName(width)} background-image`}
        />
        {children}
        <ScrollToTopButton />
      </div>
    </div>
  );
};

export default PageWrapper;
