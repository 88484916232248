import React from "react";
import { ProgramMember } from "../../models/programMembers.model";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import useWindowSize from "../../utils/useWindowSize";
import Card from "../Card/Card";
import "./TeamInfoTile.scss";

interface TeamInfoTileProps {
  participant: ProgramMember;
  allowFlipTile: boolean;
}
const TeamInfoTile = ({ participant }: TeamInfoTileProps) => {
  const renderProfileTile = ({
    firstName,
    lastName,
    programMemberPronounTypeName: memberPronouns,
    programMemberRoleTypeDomain: memberRole,
    market,
  }: ProgramMember): JSX.Element => {
    return (
      <div className="team-profile-front-tile-info-container">
        <div className="team-profile-name-container">{`${firstName} ${lastName}`}</div>
        <div className="team-profile-role-container">{`${
          memberRole[0].programMemberRoleTypeName
        }${market ? " | " + market : ""}`}</div>
        <div className="team-profile-role-container">{`${memberPronouns}`}</div>
      </div>
    );
  };

  const { image } = participant;

  const imgUrl = `data:${image.contentType};${image.encoding},${image.data}`;
  const { width } = useWindowSize();

  return (
    <div
      className="team-profile-tile-container profile-tile-margin"
      data-testid="team-profile-front-tile-container"
    >
      <Card>
        <div className="about-info-container">
          <div
            className={`team-profile-front-tile-image-container ${getResponsiveClassName(
              width
            )}`}
            style={{
              backgroundImage: `url(${imgUrl})`,
            }}
          />
          {renderProfileTile(participant)}
        </div>
      </Card>
    </div>
  );
};

export default TeamInfoTile;
