import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { getProgramMembers } from "../../ApiHelper";
import aboutHeroLg from "../../assets/images/headers/about/About_L.png";
import aboutHeroMd from "../../assets/images/headers/about/About_M.png";
import aboutHeroSm from "../../assets/images/headers/about/About_S.png";
import aboutHeroXl from "../../assets/images/headers/about/About_XL.png";
import AboutInfoList from "../../components/AboutInfoList/AboutInfoList";
import Button from "../../components/Buttons/Button";
import Card from "../../components/Card/Card";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import { Event } from "../../utils/consts/event";
import { getBanner } from "../../utils/imageUtils";
import useWindowSize from "../../utils/useWindowSize";
import "./AboutPage.scss";
import { sortProgramMembers } from "./AboutPage.util";

const banners = [aboutHeroXl, aboutHeroLg, aboutHeroMd, aboutHeroSm];

const event = Event;

type AboutPageProps = {
  isPrelaunch?: boolean;
};

const openInNewTab = (url) => {
  window.open(url, "_blank", "noreferrer");
};

const AboutPage: React.FC<AboutPageProps> = ({ isPrelaunch }) => {
  const {
    data: programMembers,
    error: programMembersError,
    isLoading: loadingProgramMembers,
  } = useQuery(["programMembers"], getProgramMembers, {
    refetchOnWindowFocus: false,
  });

  const sortedProgramMembers = useMemo(() => {
    if (!loadingProgramMembers && !programMembersError) {
      return sortProgramMembers(programMembers);
    }
    return [];
  }, [programMembers, loadingProgramMembers, programMembersError]);

  const { width } = useWindowSize();
  const url =
    "https://sparkthink.slalom.com/survey/s/35c6266e-78c0-46d5-b519-151351845bf3";
  return (
    <PageWrapper
      bannerImage={getBanner(width, banners)}
      isPrelaunch={isPrelaunch}
    >
      <div className="page-container">
        <div className="section-container">
          <section className="about-welcome">
            <section className="welcome-section">
              <SectionHeader
                sectionTitleString={"Welcome to Hacktober!"}
                width={width}
              ></SectionHeader>
              <div
                className={"about-notes-section welcome-section about-welcome"}
              >
                <Card>
                  <div className="about-page-text-body-container">
                    <div className="about-section-title">
                      Thank You from InfoSec!
                    </div>
                    {event.aboutPage.eventIntroText}
                  </div>
                </Card>
              </div>
            </section>
          </section>
          <section className={"contact-section-container"}>
            <Card containerClass="contact-card">
              <div className="about-page-card-text-body-container">
                <div className="contact-section-title">Got feedback?</div>
                {event.aboutPage.aboutEventsFeedbackText}
                <div
                  className={
                    " about-page-btn-container about-page-card-footer "
                  }
                >
                  <a
                    href="mailto: hacktober@slalom.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="app-button-primary about-contact-button-text"
                  >
                    CONTACT US
                  </a>
                </div>
              </div>
            </Card>
            <Card containerClass="contact-card">
              <div className="about-page-card-text-body-container">
                <div className="contact-section-title">
                  Join the Zenith Build Team!
                </div>
                {event.aboutPage.aboutEventsJoinText}
                <div
                  className={"about-page-btn-container about-page-card-footer"}
                >
                  <Button
                    type="button"
                    value="JOIN US"
                    onClick={() => openInNewTab(url)}
                  />
                </div>
              </div>
            </Card>
            <Card containerClass="contact-card">
              <div className="about-page-card-text-body-container">
                <div className="contact-section-title">Caught a bug?</div>
                {event.aboutPage.aboutEventsBugText}
                <div
                  className={"about-page-btn-container about-page-card-footer"}
                >
                  <a
                    href="mailto: zenith@slalom.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="app-button-primary about-contact-button-text"
                  >
                    CONTACT US
                  </a>
                </div>
              </div>
            </Card>
          </section>
          {sortedProgramMembers.map((team, index) => {
            return (
              <div key={`about-page-team-${index}`}>
                <SectionHeader
                  sectionTitleString={team.teamName}
                  width={width}
                ></SectionHeader>
                <AboutInfoList participants={team.teamMembers} />
              </div>
            );
          })}
        </div>
      </div>
    </PageWrapper>
  );
};

AboutPage.propTypes = {
  isPrelaunch: PropTypes.bool,
};

export default AboutPage;
