export interface ApiConfig {
  baseURL: string;
}

export interface AmplifyConfigOAuth {
  domain: string;
  scope: Array<string>;
  redirectSignIn: string;
  redirectSignOut: string;
  responseType: string;
}

export interface AmplifyConfig {
  aws_project_region: string;
  aws_cognito_region: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
  oauth: AmplifyConfigOAuth;
}

interface ConfigParams {
  userPoolId: string;
  webClientId: string;
  domain: string;
}

export type EventType = "hacktober" | "pride" | "scl";

const generateConfig = (params: ConfigParams) => {
  return {
    aws_project_region: "us-east-1",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: params.userPoolId,
    aws_user_pools_web_client_id: params.webClientId,
    oauth: {
      domain: params.domain,
      scope: ["email", "openid", "profile"],
      redirectSignIn: `${window.location.origin}`,
      redirectSignOut: `${window.location.origin}/signout`,
      responseType: "code",
    },
  };
};

const environments = {
  local: {
    apiBaseUrl: "https://api.events.devslalom.com/hacktober-api",
    auth: {
      userPoolId: "us-east-1_HZ628eK4y",
      webClientId: "6f50jimf8ntccllpfqljkckj5n",
      domain: "hacktober-dev.auth.us-east-1.amazoncognito.com",
    },
  },
  dev: {
    apiBaseUrl: "https://api.events.devslalom.com/hacktober-api",
    auth: {
      userPoolId: "us-east-1_HZ628eK4y",
      webClientId: "6f50jimf8ntccllpfqljkckj5n",
      domain: "hacktober-dev.auth.us-east-1.amazoncognito.com",
    },
  },
  test: {
    apiBaseUrl: "https://api.events.testslalom.com/hacktober-api",
    auth: {
      userPoolId: "us-east-1_UQEfPkeEu", // TODO: Need to configure Cognito in Test environment
      webClientId: "29pf7mam1irgatrfmjq7cgarqs",
      domain: "hacktober-test.auth.us-east-1.amazoncognito.com",
    },
  },
  prod: {
    apiBaseUrl: "https://4wfxn8vvjh.execute-api.us-east-1.amazonaws.com/prod",
    auth: {
      userPoolId: "us-east-1_CFYFAOcZp",
      webClientId: "7p9mj77kac2to58q35qvecoih6",
      domain: "hacktober-prod.auth.us-east-1.amazoncognito.com",
    },
  },
};

// extract domain for environment config selection
const currentDomain = /:\/\/([^/]+)/.exec(window.location.href)[1];

let currentEnvironment = null;

switch (currentDomain) {
  case "hacktober.slalom.com":
    currentEnvironment = environments.prod;
    break;
  case "hacktober.events.devslalom.com":
    currentEnvironment = environments.dev;
    break;
  case "hacktober.events.testslalom.com":
    currentEnvironment = environments.test;
    break;
  default: {
    currentEnvironment = environments.local;
    break;
  }
}

export const amplifyConfig = generateConfig(currentEnvironment.auth);
export const apiBaseUrl = currentEnvironment.apiBaseUrl;
export const appsyncUrl = currentEnvironment.appsyncUrl;
export const appsyncRegion = currentEnvironment.appsyncRegion;
