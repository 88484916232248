import React, { FC, useState } from "react";
import { queryCache, useMutation } from "react-query";
import { postSubmission } from "../../../ApiHelper";
import { Answer, Submission } from "../../../models/challenge.model";
import Button from "../../Button/Button";
import Card from "../../Card/Card";
import LoadingSpinner from "../../LoadingSpinner";
import { ChallengeViewProps } from "../ScavengerHunt.interface";
import Linkify from "./Linkify";

const keys = [
  "GenAI site",
  "security sharepoint",
  "Security Tips",
  "Slalom Sensitivity",
  "AI office",
  "personal data",
  "gaining approval",
  "Acceptable Use Policy",
  "AI tool",
  "Slalom Sensitivity label",
  "security home page",
];

const ChallengeView: FC<ChallengeViewProps> = ({
  challenge,
  setChallenge,
  upsert,
}) => {
  const [selection, setSelection] = useState<Answer>(null);
  const [submission, setSubmission] = useState<Submission>(null);

  const [submit, { status }] = useMutation(postSubmission, {
    onSettled: () => {
      queryCache.invalidateQueries("participant");
      queryCache.invalidateQueries("participants");
    },
  });

  const handleSubmit = () => {
    submit({
      answerId: selection.id,
      challengeId: challenge.id,
      user: null,
      position: challenge.order,
    }).then((data) => {
      // If API throws an error, return to board
      if (!data) {
        setChallenge(null);
      } else {
        setSubmission(data.submission);
        setSelection(null);
        upsert(data);
      }
    });
  };

  const conditionals = (id: number): string => {
    let classes = "";

    if (selection?.id === id) classes += "selected";
    if (submission?.answerId === id) {
      classes += submission.isCorrect ? " correct" : " incorrect";
    }

    return classes;
  };

  return (
    <Card containerClass="challenge-view">
      <Linkify
        content={challenge.description}
        keys={keys}
        url={challenge.url}
      />

      {/* Render answers grid */}
      <div className="answers-container">
        {challenge.answers.map((answer) => (
          <button
            key={answer.id}
            className={`answer-button ${conditionals(answer.id)}`}
            onClick={() => (!submission ? setSelection(answer) : null)}
          >
            <p>{answer.description}</p>
            <div className={`answer-radio ${conditionals(answer.id)}`}>
              <div className={`dot ${conditionals(answer.id)}`} />
            </div>
          </button>
        ))}
      </div>

      <div className="challenge-footer">
        <LoadingSpinner
          isLoading={status === "loading"}
          size="big"
          color="#1FF808"
        >
          <div className="button-container">
            <button className="back" onClick={() => setChallenge(null)}>
              BACK
            </button>
            <Button
              type="button"
              onClick={handleSubmit}
              disabled={!selection || !!submission}
              value="SUBMIT"
            />
          </div>
          {!selection && !submission && <p>Complete section to continue</p>}
          {submission && (
            <p>
              {submission.isCorrect
                ? "Congratulations! You got it right!"
                : "Incorrect! Try again tomorrow."}
            </p>
            // Use this in the future
            // <p>{submission.explanation}</p>
          )}
        </LoadingSpinner>
      </div>
    </Card>
  );
};

export default ChallengeView;
