import React, { useState } from "react";
import "./FAQTile.scss";
import upArrow from "../../assets/images/up-arrow.svg";
import downArrow from "../../assets/images/down-arrow.svg";
import Card from "../Card/Card";
import {
  BOOK_REGEX,
  JOIN_EVENTS_TEAM_REGEX,
  KNOW_THE_CODE_TITLE_1,
  KNOW_THE_CODE_TITLE_2,
  KNOW_THE_CODE_TITLE_3,
  KNOW_THE_CODE_TITLE_4,
  KNOW_THE_CODE_TITLE_5,
  KNOW_THE_CODE_TITLE_6,
  KNOW_THE_CODE_TITLE_7,
  PRISM_SHAREPOINT_REGEX,
  SECURITY_CHAMPION_LINK,
  SURVIVAL_TIP_TITLE_1,
  SURVIVAL_TIP_TITLE_2,
  SURVIVAL_TIP_TITLE_3,
  WORKDAY_TRAININGS_REGEX,
  ZENITH_PLAFORM_MICROSOFT_FORM_REGEX,
} from "./FAQTileRegexPatterns";

type FAQType = {
  title: string;
  body: Array<{ itemHeader?: string; itemText: string }>;
};
interface FAQTileProps {
  faq: FAQType;
}

interface FaqTileImageProps {
  isCollapsed: boolean;
}

const parseContentLinks = (content) => {
  return content.split(" ").map((part, index: number) =>
    WORKDAY_TRAININGS_REGEX.test(part) ? (
      <a
        href="https://www.myworkday.com/slalom/learning/path/86be59b3a73901015236a7d6a80b0000/course/89a48a2f0b800100e1aff221eb01dde2?type=9882927d138b100019b6a2df1a46018b"
        target="_blank"
        rel="noopener noreferrer"
        key={index}
        className="hoverUnderline"
      >
        Check out this list of trainings!
      </a>
    ) : BOOK_REGEX.test(part) ? (
      <em>Read This to Get Smarter</em>
    ) : PRISM_SHAREPOINT_REGEX.test(part) ? (
      <a
        href="https://twodegrees1.sharepoint.com/teams/IandD/SitePages/PRISM.aspx"
        target="_blank"
        rel="noopener noreferrer"
        className="hoverUnderline"
      >
        SharePoint page&nbsp;
      </a>
    ) : JOIN_EVENTS_TEAM_REGEX.test(part) ? (
      <a
        href="https://sparkthink.slalom.com/survey/s/63325a9d-ce4b-4da6-9538-86411c87a051"
        target="_blank"
        rel="noopener noreferrer"
        className="hoverUnderline"
      >
        Sign up here!
      </a>
    ) : ZENITH_PLAFORM_MICROSOFT_FORM_REGEX.test(part) ? (
      <a
        href="https://forms.office.com/r/SnpEb782b7"
        target="_blank"
        rel="noopener noreferrer"
        className="hoverUnderline"
      >
        here.
      </a>
    ) : SURVIVAL_TIP_TITLE_1.test(part) ? (
      <em>My computer... my precious!</em>
    ) : SURVIVAL_TIP_TITLE_2.test(part) ? (
      <em>Paper data is just as important as digital data!</em>
    ) : SURVIVAL_TIP_TITLE_3.test(part) ? (
      <em>Stranger danger!</em>
    ) : KNOW_THE_CODE_TITLE_1.test(part) ? (
      <em>I see security requirements...</em>
    ) : KNOW_THE_CODE_TITLE_2.test(part) ? (
      <em>Be secure, be very secure!</em>
    ) : KNOW_THE_CODE_TITLE_3.test(part) ? (
      <em>We all go a little mad sometimes</em>
    ) : KNOW_THE_CODE_TITLE_4.test(part) ? (
      <em>Whatever you do, don&apos;t use personal accounts!</em>
    ) : KNOW_THE_CODE_TITLE_5.test(part) ? (
      <em>No laptop left behind</em>
    ) : KNOW_THE_CODE_TITLE_6.test(part) ? (
      <em>Double tap: return it when you leave it</em>
    ) : KNOW_THE_CODE_TITLE_7.test(part) ? (
      <em>See something? Say something!</em>
    ) : part.endsWith("slalom.com") ? (
      <a
        href={`mailto:${part}`}
        target="_blank"
        rel="noopener noreferrer"
        className="hoverUnderline"
      >
        {part}
      </a>
    ) : part.includes(
        "teams/Slalom/SiteAssets/SitePages/Slalom-City-LImits/ics-how-to.docx"
      ) ? (
      <a
        href={`${part}`}
        target="_blank"
        rel="noopener noreferrer"
        className="hoverUnderline"
      >
        {"ICS How To Doc"}
      </a>
    ) : SECURITY_CHAMPION_LINK.test(part) ? (
      <a
        href="https://twodegrees1.sharepoint.com/:b:/t/EventsPlatform/ETd_TGNPc_lIkvArORx6XMcBvn1Gs6xwW504hqqfjkKJUg?e=8ORwHT"
        target="_blank"
        rel="noopener noreferrer"
        className="hoverUnderline"
      >
        here.
      </a>
    ) : (
      part + " "
    )
  );
};

const FaqTileImage: React.FC<FaqTileImageProps> = ({
  isCollapsed,
}): JSX.Element => {
  return (
    <div>
      <img
        src={isCollapsed ? downArrow : upArrow}
        alt="up-arrow-icon"
        className={`faq-tile-arrow-icon faq-tile-arrow-icon-${isUpOrDown(
          isCollapsed
        )}`}
      />
    </div>
  );
};

const renderCollapsedFAQTile = ({ title }: FAQType): JSX.Element => {
  return (
    <div className="faq-tile-title-container">
      <div className="faq-tile-title">{title}</div>
      <FaqTileImage isCollapsed={true} />
    </div>
  );
};

const renderExpandedFAQTile = ({ title, body }: FAQType): JSX.Element => {
  return (
    <React.Fragment>
      <div className="faq-tile-title-container">
        <div className="faq-tile-title">{title}</div>
        <FaqTileImage isCollapsed={false} />
      </div>
      {body.map((item) =>
        item.itemHeader ? (
          <div className="faq-tile-header-container" key={item.itemText}>
            <div style={{ fontWeight: "bold" }}>{item.itemHeader}</div>
            <div>{parseContentLinks(item.itemText)}</div>
          </div>
        ) : (
          <div className="faq-tile-body-container" key={item.itemText}>
            <div>{parseContentLinks(item.itemText)}</div>
          </div>
        )
      )}
    </React.Fragment>
  );
};

const renderCollapsedOrExpandedFAQTile = (faq, isCollapsed): JSX.Element => {
  return isCollapsed ? renderCollapsedFAQTile(faq) : renderExpandedFAQTile(faq);
};

const isUpOrDown = (isCollapsed) => {
  return isCollapsed ? "up" : "down";
};

const FAQTile = ({ faq }: FAQTileProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const handleIsCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };
  return (
    <div className="faq-tile-container" onClick={handleIsCollapsed}>
      <Card containerStyle={{ marginBottom: "16px" }}>
        {renderCollapsedOrExpandedFAQTile(faq, isCollapsed)}
      </Card>
    </div>
  );
};
export default FAQTile;
