import React, { ReactChild, useCallback, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

interface IntersectionObserverWrapperProps {
  children: ReactChild;
  elementId: string;
  className?: string;
  onVisibilityChange: (
    elementId: string,
    isVisible: boolean,
    ref: number
  ) => void;
}

const IntersectionObserverWrapper: React.FC<
  IntersectionObserverWrapperProps
> = ({
  children,
  elementId,
  className,
  onVisibilityChange,
}): React.ReactElement => {
  const [element, setElement] = useState(null);
  const { ref, inView } = useInView({
    threshold: 0.2,
  });
  const setRef = useCallback(
    (node) => {
      if (node) {
        ref(node);
        setElement(node);
      }
    },
    [ref]
  );

  useEffect(() => {
    if (element) {
      const top = element.getBoundingClientRect().top;
      onVisibilityChange(elementId, inView, top);
    }
  }, [inView, elementId, element, onVisibilityChange]);

  return (
    <div className={className} ref={setRef} id={elementId}>
      {children}
    </div>
  );
};

export default IntersectionObserverWrapper;
