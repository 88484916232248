export const BOOK_REGEX = /(ReadThistoGetSmarter)/;
export const WORKDAY_TRAININGS_REGEX = /LGBTWhat-workday-training-link/;
export const PRISM_SHAREPOINT_REGEX = /prism-sharepoint-site/;
export const JOIN_EVENTS_TEAM_REGEX = /join-events-platform-sparkthink/;
export const ZENITH_PLAFORM_MICROSOFT_FORM_REGEX =
  /zenith-platform-microsoft-form/;
export const SURVIVAL_TIP_TITLE_1 = /survival-tip-title-1/;
export const SURVIVAL_TIP_TITLE_2 = /survival-tip-title-2/;
export const SURVIVAL_TIP_TITLE_3 = /survival-tip-title-3/;
export const KNOW_THE_CODE_TITLE_1 = /know-the-code-title-1/;
export const KNOW_THE_CODE_TITLE_2 = /know-the-code-title-2/;
export const KNOW_THE_CODE_TITLE_3 = /know-the-code-title-3/;
export const KNOW_THE_CODE_TITLE_4 = /know-the-code-title-4/;
export const KNOW_THE_CODE_TITLE_5 = /know-the-code-title-5/;
export const KNOW_THE_CODE_TITLE_6 = /know-the-code-title-6/;
export const KNOW_THE_CODE_TITLE_7 = /know-the-code-title-7/;
export const SECURITY_CHAMPION_LINK = /security-champion-link/;
