import React, { FC } from "react";
import "./WrapPage.scss";
import useWindowSize from "../../utils/useWindowSize";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import { Event } from "../../utils/consts/event";
import Button from "../../components/Button/Button";
import { EButtonStyle } from "../../utils/buttonUtils";
import Footer from "../../components/Footer/Footer";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import Card from "../../components/Card/Card";

const event = Event;
export const EVENTS_VIEW_RECORDINGS_LINK = event.wrapPage.viewRecordingLink;
export const SHAREPOINT_SITE = event.wrapPage.sharePointSite;
export const FEEDBACK_LINK = event.wrapPage.feedbackEmailAddress;

const renderButtonContainer = (width: number): JSX.Element => (
  <div className={`wrapup-button-container-${getResponsiveClassName(width)}`}>
    <div className={`top-buttons top-buttons-${getResponsiveClassName(width)}`}>
      <Button
        style={EButtonStyle.SECONDARY}
        type="button"
        value="View Recordings ›"
        onClick={() => window.open(EVENTS_VIEW_RECORDINGS_LINK, "_blank")}
      />
      {/* <Button
        style={EButtonStyle.SECONDARY}
        type="button"
        value="Visit our Sharepoint site ›"
        onClick={() => window.open(SHAREPOINT_SITE, "_blank")}
      /> */}
      <Button
        style={EButtonStyle.PRIMARY}
        type="button"
        value="Give Feedback"
        onClick={() => window.open(FEEDBACK_LINK, "_blank")}
      />
    </div>
  </div>
);

const WrapPage: FC = () => {
  const { width } = useWindowSize();

  return (
    <div className={`wrap-page-bg ${getResponsiveClassName(width)}`}>
      <div className="wrap-page">
        {" "}
        <div className={`wrap-page-content ${getResponsiveClassName(width)}`}>
          <Card
            containerClass={`wrap-card wrap-card-${getResponsiveClassName(
              width
            )}`}
          >
            <SectionHeader
              sectionTitleString={event.wrapPage.title}
              width={width}
            ></SectionHeader>
            <div>
              {event.wrapPage.message}
              <span className="bold">{event.wrapPage.message2}</span>
            </div>
            <br />
            <div className="bold">{event.wrapPage.message3}</div>
            {renderButtonContainer(width)}
          </Card>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default WrapPage;
