import classNames from "classnames";
import React, { useEffect } from "react";
import { MAX_CHALLENGES, MAX_WORD_LENGTH } from "../../constants/settings";
import { getGuessStatuses } from "../../lib/words";
import { WordleData, WordleDataUserAnswers } from "../../models/wordleData";
import Cell from "../Cell";
import styles from "./Grid.module.scss";

interface GridProps {
  currentGuess: any;
  guesses: WordleDataUserAnswers[];
  isJiggling: any;
  setIsJiggling: any;
  wordleData: WordleData;
}

const Grid = ({
  currentGuess,
  guesses,
  isJiggling,
  setIsJiggling,
}: GridProps) => {
  const empties =
    MAX_CHALLENGES > guesses.length
      ? Array(MAX_CHALLENGES - guesses.length - 1).fill("")
      : [];

  useEffect(() => {
    setTimeout(() => {
      if (isJiggling) setIsJiggling(false);
    }, 500);
    // eslint-disable-next-line
  }, [isJiggling]);

  return (
    <div className={styles.grid}>
      {guesses.map((value, i) => (
        <CompletedRow key={i} userAnswer={value} />
      ))}
      {guesses.length < MAX_CHALLENGES && (
        <CurrentRow guess={currentGuess} isJiggling={isJiggling} />
      )}
      {empties.map((_, i) => (
        <EmptyRow key={i} />
      ))}
    </div>
  );
};

interface CurrentRowProps {
  guess: any;
  isJiggling;
}

const CurrentRow = ({ guess, isJiggling }: CurrentRowProps) => {
  const emptyCells = Array(MAX_WORD_LENGTH - guess.length).fill("");
  const cells = [...guess, ...emptyCells];

  const classes = classNames({
    [styles.row]: true,
    [styles.jiggle]: isJiggling,
  });

  return (
    <div className={classes}>
      {cells.map((letter, index) => (
        <Cell key={index} value={letter} />
      ))}
    </div>
  );
};

interface CompletedRowProps {
  userAnswer: WordleDataUserAnswers;
}

const CompletedRow = ({ userAnswer }: CompletedRowProps) => {
  const cells = userAnswer.answer.split("");
  const statuses = getGuessStatuses(userAnswer.answer, userAnswer.hint);

  return (
    <div className={styles.row}>
      {cells.map((letter, index) => (
        <Cell
          key={index}
          position={index}
          value={letter}
          isCompleted
          status={statuses[index]}
        />
      ))}
    </div>
  );
};

const EmptyRow = () => {
  const cells = Array(MAX_WORD_LENGTH).fill("");

  return (
    <div className={styles.row}>
      {cells.map((_, index) => (
        <Cell key={index} />
      ))}
    </div>
  );
};

export default Grid;
