import React from "react";
import useWindowSize from "../../utils/useWindowSize";
import Card from "../Card/Card";
import "./PrizesList.scss";
import SectionHeader from "../SectionHeader/SectionHeader";

type PrizesListProps = {
  prizes: { title: string; text: string }[];
  type?: "Market" | "Individual";
};

const PrizesList: React.FC<PrizesListProps> = ({ prizes, type }) => {
  const { width } = useWindowSize();
  return (
    <div className="prizes-list-container section-container">
      <SectionHeader
        sectionTitleString={type ? type + " Prizes" : "Prizes"}
        width={width}
      ></SectionHeader>
      <div className="prizes-list">
        <div className="flex-container">
          {prizes &&
            prizes.map(({ text, title }, idx) => (
              <Card key={idx}>
                <h2 className="winners-card-title">{title}</h2>
                <p>{text}</p>
              </Card>
            ))}
        </div>
      </div>
    </div>
  );
};

export default PrizesList;
