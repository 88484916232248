import React from "react";
import { Icon } from "semantic-ui-react";
import PropTypes from "prop-types";

const LoadingSpinner = ({ isLoading, error, children, color, size }) =>
  isLoading ? (
    <Icon style={{ color: color }} loading name="spinner" size={size} />
  ) : error ? (
    "An error has occurred: " + error.message
  ) : (
    children
  );

export default LoadingSpinner;

LoadingSpinner.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.any,
  children: PropTypes.any,
  color: PropTypes.string,
  size: PropTypes.string,
};
