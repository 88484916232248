import React from "react";
import { deviceWidthBreakpoints } from "../../utils/useWindowSize";
import { ELeaderboardOption } from "../Leaderboard/Leaderboard.util";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import TopLeaderCard from "../TopLeaderCard/TopLeaderCard";

const { TABLET_MAX } = deviceWidthBreakpoints;

const topLeadersResponsiveClassName = (width, listLength) =>
  `leaders ${width < TABLET_MAX ? "leaders-mobile" : ""} ${
    listLength && listLength > 3 ? "" : "leaders-small"
  }`;

type LoadLeadersProps = {
  width: any;
  sortedParticipants: any;
  selectedOption: ELeaderboardOption;
  error: any;
  isLoading: boolean;
};

const TopLeaders: React.FC<LoadLeadersProps> = ({
  width,
  sortedParticipants,
  selectedOption,
  error,
  isLoading,
}: LoadLeadersProps) => {
  return (
    <div
      className={topLeadersResponsiveClassName(
        width,
        sortedParticipants?.length
      )}
    >
      <LoadingSpinner
        isLoading={isLoading}
        error={error}
        color={"#00A4F9"}
        size={"massive"}
      >
        {sortedParticipants &&
          sortedParticipants
            .slice(0, 3)
            .map((participant, idx) => (
              <TopLeaderCard
                data={participant}
                key={`${participant.user}-${idx}`}
                type={selectedOption}
                position={idx}
              />
            ))}
      </LoadingSpinner>
    </div>
  );
};

export default TopLeaders;
