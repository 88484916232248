import React, { FC } from "react";
import ChallengePoints from "../../../assets/images/scavenger-hunt/scavenger_200.png";
import ChallengeComplete from "../../../assets/images/scavenger-hunt/scavenger_correct.png";
import { ChallengeTileProps } from "../ScavengerHunt.interface";

const ChallengeTile: FC<ChallengeTileProps> = ({
  available,
  challenge,
  setChallenge,
}) => {
  const complete = challenge.submission?.isCorrect;
  return (
    <div className="challenge-tile">
      {/* Render invisible hexagon for clickable area */}
      <div
        className={`hexagon ${complete ? null : "incomplete"} ${
          available ? "available" : null
        }`}
        onClick={() =>
          available && !complete ? setChallenge(challenge) : null
        }
      />

      {/* Render the hexagon image */}
      <img src={complete ? ChallengeComplete : ChallengePoints} alt="" />
    </div>
  );
};

export default ChallengeTile;
