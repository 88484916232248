import React, { useRef, useState } from "react";
import "./Video.scss";

type VideoProps = {
  showPlayPause: boolean;
  video: string;
};

const Video: React.FC<VideoProps> = ({
  showPlayPause,
  video,
}): React.ReactElement => {
  const videoElement = useRef();
  const [videoPaused, setVideoPaused] = useState(false);

  const togglePausePlayVideo = (): void => {
    const video: HTMLVideoElement = videoElement?.current;

    if (!video) {
      return;
    }

    const pause: boolean = !videoPaused;

    pause ? video.pause() : video.play();

    setVideoPaused(pause);
  };

  return (
    <>
      {showPlayPause && (
        <img
          src={require("../../assets/images/pause.png")}
          onClick={() => togglePausePlayVideo()}
          className="app-video__play-pause-icon"
          alt="play/pause button"
        />
      )}
      <div className="app-video">
        <video
          ref={videoElement}
          autoPlay
          loop
          muted
          playsInline
          preload="true"
          className="prelaunch-background-video"
          id="bgVid"
        >
          <source src={video} type="video/mp4" />
        </video>
      </div>
    </>
  );
};

export default Video;
