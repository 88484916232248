import PropTypes from "prop-types";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { getEvent } from "../../ApiHelper";
import divider from "../../assets/images/events-divider.svg";
import pointsIcon from "../../assets/images/points-trophy-black.svg";
import { EventActionButton } from "../Buttons/EventActionButton";
import Card from "../Card/Card";
import LoadingSpinner from "../LoadingSpinner";
import ProfileCardList from "../ProfileCardList/ProfileCardList";
import {
  cleanUpName,
  extendContent,
  getDay,
  getMonth,
  getTime,
  shortenContent,
} from "./";
import "./EventCard.scss";

const renderEventCard = ({
  startDateTime,
  title,
  points,
  content,
  hostMarket,
  displayDetails,
  setDisplayDetails,
  speakerProfiles,
  maxLength,
}) => (
  <div className="event-details">
    <div className="event-details__top-row">
      <div className="event-details__date">
        <div>{getMonth(startDateTime)}</div>
        <div>{getDay(startDateTime)}</div>
        <div>{getTime(startDateTime)}</div>
      </div>
      <img src={divider} alt="divider" />
      <div className={"event-details__name"}>
        <span>{cleanUpName(title)}</span>
      </div>
    </div>
    <div className="event-points">
      <div style={{ marginRight: "12px" }}>
        <img
          src={pointsIcon}
          alt="event-card-points-icon"
          className="event-card-points-icon"
        />
      </div>
      <div className="event-points-value">{points} Points</div>
    </div>
    <div className="event-content">
      {!displayDetails ? shortenContent(content, maxLength) : content}
    </div>
    {content.length > maxLength && (
      <div className="event-card-secondary-btn-wrapper">
        <button
          className="app-button app-button-text"
          type="button"
          onClick={() => {
            setDisplayDetails(!displayDetails);
          }}
        >
          {!displayDetails ? "Continue Reading  \u203A" : "Show Less \u203A"}
        </button>
      </div>
    )}
    {/* <div className="event-details__location">
      <img src={globe} alt="globe" className="event-card-points-icon" />{" "}
      <span className="event-details__location__market">{hostMarket}</span>
    </div> */}
    <div className="event-details__speakers-container">
      {speakerProfiles &&
        speakerProfiles.map((speaker, index) => (
          <div key={index} className="event-details__speaker-container">
            <div>{`${speaker.firstName} ${speaker.lastName}`}</div>
            <div className="event-details__speaker-container__market">
              {speaker.market}
            </div>
            <a
              className="link"
              href={`mailto:${speaker.user}`}
              data-testid="contact-email"
            >
              {speaker.user}
            </a>
          </div>
        ))}
    </div>
  </div>
);
const EventDetails = ({ eventId }) => {
  const {
    data: eventDetailData,
    error: eventDetailError,
    isLoading: eventDetailIsLoading,
  } = useQuery(["eventDetail", eventId], getEvent, {
    enabled: eventId,
  });
  let content = "";
  let speakerProfiles = [];
  if (!eventDetailIsLoading) {
    content = eventDetailData?.content;
    speakerProfiles = eventDetailData?.speakerProfiles;
  }

  return (
    <LoadingSpinner
      isLoading={eventDetailError || eventDetailData === undefined}
      error={eventDetailIsLoading}
      size="massive"
    >
      <div className="event-details-expanded">{extendContent(content)}</div>
      {speakerProfiles && (
        <div style={{ marginBottom: "30px" }}>
          <ProfileCardList participants={speakerProfiles} />
        </div>
      )}
    </LoadingSpinner>
  );
};

const EventCard = (props) => {
  const maxLength = 231;
  const [displayDetails, setDisplayDetails] = useState(false);
  const { data: eventDetailData, isLoading: eventDetailIsLoading } = useQuery(
    ["eventDetail", props.eventId],
    getEvent,
    {
      enabled: props.eventId,
    }
  );
  let speakerProfiles = [];
  if (!eventDetailIsLoading) {
    speakerProfiles = eventDetailData?.speakerProfiles;
  }

  return (
    <Card containerClass="event-card-container">
      {renderEventCard({
        ...props,
        displayDetails,
        setDisplayDetails,
        speakerProfiles,
        maxLength,
      })}
      <EventActionButton
        url={props.signupLink}
        isPastEvent={props.isPastEvent}
        isOngoing={props.isOngoing}
        liked={props.isLiked}
        eventId={props.eventId}
        activityLikeId={props.activityLikeId}
      />
    </Card>
  );
};

EventCard.propTypes = {
  startDateTime: PropTypes.string,
  endDateTime: PropTypes.string,
  title: PropTypes.string,
  points: PropTypes.number,
  content: PropTypes.string,
  signupLink: PropTypes.string,
  detail: PropTypes.bool,
  speakers: PropTypes.array,
  eventId: PropTypes.number,
  showButton: PropTypes.bool,
  activityLikeId: PropTypes.number,
  isPastEvent: PropTypes.bool,
  isOngoing: PropTypes.bool,
  isLiked: PropTypes.bool,
};

EventDetails.propTypes = {
  eventId: PropTypes.number,
};

renderEventCard.propTypes = {
  startDateTime: PropTypes.string,
  endDateTime: PropTypes.string,
  title: PropTypes.string,
  points: PropTypes.number,
  content: PropTypes.string,
  displayDetails: PropTypes.bool,
  setDisplayDetails: PropTypes.func,
  eventId: PropTypes.number,
  speakerProfiles: PropTypes.array,
  maxLength: PropTypes.number,
};

export default EventCard;
