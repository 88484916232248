import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

function ScrollToTop({ history, children }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return <React.Fragment>{children}</React.Fragment>;
}

ScrollToTop.propTypes = {
  history: PropTypes.any,
  children: PropTypes.any,
};

export default withRouter(ScrollToTop);
