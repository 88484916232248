import React from "react";
import "./MiniGamePage.scss";
import useWindowSize, {
  deviceWidthBreakpoints,
} from "../../utils/useWindowSize";
import MiniGame from "../../components/MiniGame/MiniGame";
import {
  getResponsiveBackgroundClass,
  getMainContainerClass,
  getPageContainerClass,
} from "../../utils/responsiveClassHelpers";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import Card from "../../components/Card/Card";

import homeBannerDesktop from "../../assets/images/headers/home/homepage-hero-image.png";
import homeBannerTablet from "../../assets/images/headers/home/homepage-hero-image-tablet.png";
import homeBannerMobile from "../../assets/images/headers/home/homepage-hero-image-mobile.png";
import ScrollToTop from "../../components/ScrollToTop";

const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;

const MiniGamePage = (): JSX.Element => {
  const { width } = useWindowSize();

  const bannerImg =
    width > TABLET_MAX
      ? homeBannerDesktop
      : width > MOBILE_MAX
      ? homeBannerTablet
      : homeBannerMobile;

  return (
    <ScrollToTop>
      <PageWrapper bannerImage={bannerImg}>
        <div
          className={`hacktober-page ${getResponsiveBackgroundClass(width)}`}
        >
          <div className={getPageContainerClass(width)}>
            <div className={getMainContainerClass(width)}>
              {width < MOBILE_MAX ? (
                <>
                  <div className="mobile-message">
                    <Card>
                      <h3>You found the bug!</h3>
                      <br />
                      Unfortunately, this game cannot be played on mobile
                      devices at this time. Make sure you don’t miss this fun
                      experience and jump over to your laptop when you can.
                    </Card>
                  </div>
                </>
              ) : (
                <div>
                  <MiniGame />
                  <div className="points-disclaimer">
                    <Card>
                      This game is just for fun! No points will be awarded for
                      playing.
                    </Card>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </PageWrapper>
    </ScrollToTop>
  );
};

export default MiniGamePage;
