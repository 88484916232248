import React, { FC } from "react";
import Card from "../../Card/Card";
import { ChallengeBoardProps } from "../ScavengerHunt.interface";
import ChallengeRow from "./ChallengeRow";

const Unavailable = () => (
  <div className="challenges-unavailable">
    <strong>Thank you for your efforts.</strong>
    <strong>Please come back tomorrow to participate again.</strong>
  </div>
);

const ChallengeBoard: FC<ChallengeBoardProps> = ({
  challenges,
  setChallenge,
}) => {
  const firstRow = challenges?.slice(0, 4);
  const secondRow = challenges?.slice(4, 8);
  const thirdRow = challenges?.slice(8);

  const now = new Date();
  const available = challenges?.every(
    (challenge) =>
      new Date(challenge.submission?.dateTime).toDateString() !==
      now.toDateString()
  );

  return (
    <Card containerClass="challenge-board">
      <div className="board-offset">
        <ChallengeRow
          challenges={firstRow}
          available={available}
          setChallenge={setChallenge}
        />
        <ChallengeRow
          challenges={secondRow}
          available={available}
          setChallenge={setChallenge}
          offset
        />
        <ChallengeRow
          challenges={thirdRow}
          available={available}
          setChallenge={setChallenge}
        />
      </div>

      {/* Challenges Unavailable Overlay */}
      {!available && <Unavailable />}
    </Card>
  );
};

export default ChallengeBoard;
